import React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../ReduxSlice/user";
import { setAuth } from "../../ReduxSlice/auth";
import { FaEnvelope } from "react-icons/fa";
import { RiLock2Fill } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";
import { RiErrorWarningFill } from "react-icons/ri";

import axios from "axios";

const LOGIN_URL = "/auth";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

function LoginPopup({ setShowSignup, setShowHelp }) {
  const { username, password } = useSelector((state) => state.user.value);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const errRef = useRef();
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  const EMAIL_REGEX = /\S+@\S+\.\S+/;
  const [validUser, setValidUser] = useState(false);
  const [validPass, setValidPass] = useState(false);
  const [userFocus, setUserFocus] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const gotoResetPassword = () => {
    dispatch(setUserInfo({ displayBoolean: false }));
    navigate(`/reset-password`);
  };

  useEffect(() => {
    setValidUser(EMAIL_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidPass(PWD_REGEX.test(password));
  }, [password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${api_address}${LOGIN_URL}`,
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const accessToken = response?.data?.accessToken;
      const rep = response?.data?.rep;
      const dealer = response?.data?.dealer;
      const phone = response?.data?.phone;
      const roles = response?.data?.roles;
      dispatch(setAuth({ rep, dealer, phone, roles, accessToken }));
      dispatch(
        setUserInfo({ username: "", password: "", displayBoolean: false })
      );
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Incorrect Username or Password");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
    // }
  };

  function reset() {
    dispatch(setUserInfo({ displayLogin: false }));
    setShowSignup(false);
    setShowHelp(false);
  }
  return (
    <div>
      <form className="login-form">
        <div className="error-msg-text">{errMsg ? `${errMsg}` : ""}</div>
        <div
          className={`login-input-holder ${
            userFocus && !validUser
              ? "error-input"
              : validUser
              ? "valid-input"
              : ""
          }`}
        >
          <div className={`dealer-icon ${validUser ? "valid-icon" : ""}`}>
            <FaEnvelope />
          </div>
          <input
            type="email"
            name="email"
            placeholder="Email"
            id="email"
            value={username}
            onChange={(e) => {
              dispatch(setUserInfo({ username: e.target.value }));
            }}
            onFocus={() => {
              setUserFocus(true);
            }}
            required
          ></input>
          {validUser ? (
            <div className="dealer-msg valid">
              <FaCheckCircle />
            </div>
          ) : userFocus ? (
            <div className="dealer-msg error">
              <RiErrorWarningFill />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="error-msg-text">
          {userFocus && !validUser ? "Required field is missing." : ""}
        </div>
        <div
          className={`login-input-holder ${
            pwdFocus && !validPass
              ? "error-input"
              : validPass
              ? "valid-input"
              : ""
          }`}
        >
          <div className={`dealer-icon ${validPass ? "valid-icon" : ""}`}>
            <RiLock2Fill />
          </div>
          <input
            type="password"
            name="password"
            placeholder="Password"
            id="password"
            value={password}
            onChange={(e) => {
              dispatch(setUserInfo({ password: e.target.value }));
            }}
            onFocus={() => {
              setPwdFocus(true);
            }}
            required
          ></input>
          {validPass ? (
            <div className="dealer-msg valid">
              <FaCheckCircle />
            </div>
          ) : pwdFocus ? (
            <div className="dealer-msg error">
              <RiErrorWarningFill />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="error-msg-text">
          {pwdFocus && !validPass ? "Required field is missing." : ""}
        </div>
        <button className="submit" onClick={handleSubmit}>
          SIGN IN
        </button>
      </form>
      <p className="need-account">Forgot Your Password?</p>
      <h3 className="sign-up-link" onClick={() => gotoResetPassword()}>
        Reset Password
      </h3>
      <p className="need-account">Need an Account?</p>
      <h3 className="sign-up-link" onClick={reset}>
        SIGN UP
      </h3>
    </div>
  );
}

export default LoginPopup;
