import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAvailableOptions, setOptionsAvailable, setProduct } from "../../ReduxSlice/product";
import { useNavigate, createSearchParams } from "react-router-dom";

const Configurations = () => {
  const { series, allOptions, optionsSelected, allProducts, skusAvailable, optionsAvailable } = useSelector((state) => state.product.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleClick(option, choice) {
    dispatch(setProduct({ optionsSelected: { ...optionsSelected, [option]: choice } }));
  }
  function checkIfAvailable(option, choice) {
    if (optionsAvailable.hasOwnProperty(option) && Array.isArray(optionsAvailable[option])) {
      return optionsAvailable[option].includes(choice);
    }
    return false;
  }

  useEffect(() => {
    dispatch(setAvailableOptions());
  }, [optionsSelected, allOptions]);



  return (
    <div className="configurations new-config-div">
      {allOptions
        && Object.keys(allOptions)?.map((optn, index) => {
          return (
            <div className="config-option" key={index}>
              <h3 className="config-type">{optn}</h3>
              <div className="configurations-div">
                {allOptions[optn].map((choice, index) => {
                  return (
                    <div
                      key={index}
                      className={`option ${checkIfAvailable(optn, choice)
                        ? optionsSelected
                          ? Object.keys(optionsSelected).includes(optn)
                            ? optionsSelected[optn] === choice
                              ? "option-selected"
                              : "option-unselected"
                            : ""
                          : ""
                        : "not-avail"
                        }`}
                      onClick={() => {
                        if (checkIfAvailable(optn, choice)) {
                          handleClick(optn, choice);
                        }
                      }}
                    >
                      {choice}
                    </div>
                  )
                })}
              </div>
            </div>

          );
        })
      }
      {optionsSelected && Object.keys(allOptions).length > 1 &&
        <div className="option clear-options"
          onClick={() => {
            dispatch(setProduct({
              optionsSelected: false,
              allProducts: allProducts,
            }));
            navigate({
              pathname: `/products/${series?.seriesName}`,
              search: `?${createSearchParams("")}`,
            });
          }}
        >
          Clear All
        </div>
      }
    </div >
  );

};

export default Configurations;
