import React, { useEffect, useState } from "react";
import { AiOutlineDownCircle, AiOutlineUpCircle } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import ImageTransformations from "./ImageTransformations";
const CategoryBar = ({
  categories,
  catLevels,
  setCatLevels,
  setImageEffects,
  imageEffects,
  setApplyEffects,
}) => {
  const [openCats, setOpenCats] = useState(false);
  const [openSkus, setOpenSkus] = useState(false);
  const [searchValue, setSearchValue] = useState();

  return (
    <div style={{ width: "31rem", minWidth: "175px" }}>
      <h2 style={{ marginTop: "3rem", fontSize: "25px", textAlign: "left" }}>
        Select Category
      </h2>

      <div className="category-list-div">
        <div className="category-list">
          {categories?.map((i, index) => {
            return (
              <div key={index}>
                <h3
                  onClick={() => {
                    if (catLevels[0] === i.category) {
                      setCatLevels([]);
                    } else {
                      //   console.log(i);

                      setCatLevels([i.category]);
                    }
                    // console.log(catLevels);
                  }}
                  className={`${catLevels[0] === i.category ? "selected" : ""}`}
                >
                  {i.category}
                </h3>
                {catLevels[0] === i.category ? (
                  <div className="subcat-list">
                    {i.series.map((j, index) => {
                      return (
                        <div key={index}>
                          <h4
                            key={j.series}
                            onClick={() => {
                              setCatLevels([catLevels[0], j.series]);
                            }}
                            className={`${
                              catLevels[1] === j.series ? "selected" : ""
                            }`}
                          >
                            {j.series}
                          </h4>
                          {catLevels[1] === j.series ? (
                            <div className="subcat-list">
                              {j?.products?.map((k, index) => {
                                return (
                                  <div key={index}>
                                    <h4
                                      key={k}
                                      onClick={() => {
                                        setCatLevels([
                                          catLevels[0],
                                          catLevels[1],
                                          k,
                                        ]);
                                      }}
                                      className={`${
                                        catLevels[2] === k ? "selected" : ""
                                      }`}
                                    >
                                      {k}
                                    </h4>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </div>
      <ImageTransformations
        setImageEffects={setImageEffects}
        imageEffects={imageEffects}
        setApplyEffects={setApplyEffects}
      />
    </div>
  );
};

export default CategoryBar;
