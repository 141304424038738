import React, { useEffect, useState } from "react";
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from "@cloudinary/react";
import { scale } from "@cloudinary/transformation-builder-sdk/actions/resize";
import { lazyload } from "@cloudinary/react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import { useSelector } from "react-redux";
import FormatImageLink from "../Shared/FormatImageLink";

const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const ImageGallery = () => {
  const { product, images } = useSelector(
    (state) => state.product.value.currentProduct
  );
  const [mainImage, setMainImage] = useState();
  const [indexes, setIndexes] = useState([0, 1, 2, 3]);
  const [pointer, setPointer] = useState(0);
  const [statusIcons, setStatusIcons] = useState([]);
  const decodeAndFormat = FormatImageLink();
  useEffect(() => {
    setMainImage(images[0]);
    // console.log(product, 13);
  }, [images]);
  const cld = new Cloudinary({
    cloud: {
      cloudName: "da3rom333",
    },
  });

  async function getStatusIcons() {
    await axios
      .get(`${api_address}/products/getStatusIcons`)
      .then((response) => {
        setStatusIcons(response.data);
      })
      .catch((error) => {
        console.error("Error fetching status icons:", error);
      });
  }

  useEffect(async () => {
    await getStatusIcons();
  }, []);

  function addIndex() {
    if (indexes[indexes.length - 1] === images.length - 1) {
      setIndexes([indexes[1], indexes[2], indexes[3], 0]);
    } else {
      setIndexes([indexes[1], indexes[2], indexes[3], indexes[3] + 1]);
    }
  }
  function removeIndex() {
    if (indexes[0] === 0) {
      setIndexes([images.length - 1, indexes[0], indexes[1], indexes[2]]);
    } else {
      setIndexes([indexes[0] - 1, indexes[0], indexes[1], indexes[2]]);
    }
  }
  function addPointer() {
    if (pointer === images.length - 1) {
      setPointer(0);
      setMainImage(images[0]);
    } else {
      setPointer(pointer + 1);
      setMainImage(pointer + 1);
    }
  }
  function removePointer() {
    if (pointer === 0) {
      setPointer(images.length - 1);
      setMainImage(images.length - 1);
    } else {
      setPointer(pointer - 1);
      setMainImage(pointer - 1);
    }
  }
  useEffect(() => {
    setMainImage(images[pointer]);
  }, [pointer]);
  return (
    <div>
      <div className="main-img">
        <AdvancedImage
          cldImg={cld.image(mainImage).resize(scale().width(500))}
          // plugins={[lazyload()]}
        />
        {product?.status !== "A" && (
          <div
            style={{
              height: "5rem",
              marginTop: "-29rem",
              marginLeft: "0rem",

              position: "absolute",
              color: "white",
              fontSize: "54px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <AdvancedImage
              cldImg={decodeAndFormat(
                statusIcons?.find((i) => i.status === product?.status)
                  ?.icon_link
              ).resize(scale().width(170))}
            />
          </div>
        )}
      </div>
      <div className="slider">
        {/* <div>{"<"}</div> */}
        <div className="images">
          <IoIosArrowBack onClick={removeIndex} />
          {indexes.map((i) => {
            return (
              <div
                onClick={() => {
                  setMainImage(images[i]);
                }}
                key={i}
                className={`slider-img`}
              >
                <AdvancedImage
                  cldImg={cld.image(images[i]).resize(scale().width(100))}
                  plugins={[lazyload()]}
                />
              </div>
            );
          })}
          <IoIosArrowForward onClick={addIndex} />
        </div>
      </div>
      <div className="pointer">
        <IoIosArrowBack onClick={removePointer} />
        <div>
          {pointer + 1} / {images.length}
        </div>
        <IoIosArrowForward onClick={addPointer} />
      </div>
    </div>
  );
};

export default ImageGallery;
