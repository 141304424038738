import axios from "../api/axios";
import { useDispatch } from "react-redux";
import { setAuth } from "../ReduxSlice/auth";
//import useAuth from "./useAuth";

const useLogout = () => {
    //const { setAuth } = useAuth();
    const dispatch = useDispatch();
    const logout = async () => {
        //setAuth({});
        dispatch(setAuth({}));
        try {
            const response = await axios('/logout', {
                withCredentials: true
            });
        } catch (err) {
            console.error(err)
        }
    }

    return logout
}

export default useLogout