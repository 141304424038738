import React, { useEffect, useState } from "react";
import Configurations from "../Components/ProductsPage/Configurations.js";
import "../CSS/Product.css";
import { AdvancedImage, lazyload } from "@cloudinary/react";
import { Cloudinary, CloudinaryImage } from "@cloudinary/url-gen";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { createSearchParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import ImageGallery from "../Components/ProductsPage/ImageGallery";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
import { resetProductState, setProduct } from "../ReduxSlice/product";

const Product = () => {
  const gaEventTracker = useAnalyticsEventTracker("Products Page");
  useEffect(() => {
    document.title = `${seriesID} - Hampden Furnishings`;
    gaEventTracker([
      `${seriesID}, ${
        optionsSelected
          ? Object.keys(optionsSelected).map((i) => {
              return `${i}: ${optionsSelected[i]}`;
            })
          : ""
      } - Page Visited`,
      "Label Here ",
    ]);
    // window.gtag(
    //   "event",
    //   `GTAG ${seriesID} page visited`,
    //   `${
    //     optionsSelected
    //       ? Object.keys(optionsSelected).map((i) => {
    //           return `${i}: ${optionsSelected[i]}`;
    //         })
    //       : ""
    //   }`
    // );
  }, []);
  const {
    series,
    allProducts,
    currentProduct,
    optionsSelected,
    skusAvailable,
  } = useSelector((state) => state.product.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { seriesID } = useParams();
  const cld = new Cloudinary({
    cloud: {
      cloudName: "da3rom333",
    },
  });
  const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

  function getOptions() {
    var holderSet = new Set();
    allProducts?.map((p) => {
      Object.keys(p.configurations)?.map((config) => {
        holderSet.add(config);
      });
    });
    var holderObject = {};
    for (var config of holderSet) {
      var holderArray = [];
      allProducts?.map((p) => {
        if (!holderArray.includes(p.configurations[config])) {
          holderArray.push(p.configurations[config]);
        }
      });
      holderObject[config] = holderArray;
    }
    return holderObject;
  }

  function getData() {
    Axios.post(`${api_address}/products/productBySeries`, {
      series: seriesID.replace(/~/g, "/"),
    }).then((response) => {
      dispatch(
        setProduct({
          series: response.data,
          allProducts: response.data.products,
          //optionsSelected: searchParamsUse,
          currentProduct: currentProduct || response.data.products[0], // if not set based on search params, set current product to first in the list
        })
      );
      console.log(response.data, 82);
    });
  }

  const [searchParams] = useSearchParams();
  const searchParamsUse = Object.fromEntries([...searchParams]);

  useEffect(() => {
    // get products
    getData();
  }, []);
  useEffect(() => {
    if (Object.keys(searchParamsUse).length) {
      //check if the search params match any of the available skus
      if (
        skusAvailable.some((sku) =>
          Object.entries(searchParamsUse).every(
            ([key, value]) => sku[key] === value
          )
        )
      ) {
        dispatch(setProduct({ optionsSelected: searchParamsUse }));
      } else {
        console.error(
          "Search parameters do not match any available configurations!"
        );
      }
    }
  }, [skusAvailable]);

  useEffect(() => {
    dispatch(setProduct({ allOptions: getOptions() }));
  }, [allProducts]);

  useEffect(() => {
    if (series?.seriesName && Object.keys(optionsSelected)?.length) {
      navigate(
        {
          pathname: `/products/${series?.seriesName}`,
          search: `?${createSearchParams(optionsSelected)}`,
        },
        { replace: true }
      );

      //set the current product to the first product that includes all the options that were selected
      dispatch(
        setProduct({
          currentProduct: allProducts.find((product) =>
            Object.entries(optionsSelected).every(
              ([key, value]) => product.configurations[key] === value
            )
          ),
        })
      );

      gaEventTracker(
        `${seriesID}, ${
          optionsSelected
            ? Object.keys(optionsSelected).map((i) => {
                return `${i}: ${optionsSelected[i]}`;
              })
            : ""
        }, Option Changed`
      );
    }
  }, [optionsSelected]);

  useEffect(() => {
    //reset the product state to initial values when component unmounts
    return () => {
      dispatch(resetProductState());
    };
  }, [dispatch]);

  return (
    <div className="product-page">
      <div className="flex-left">{currentProduct ? <ImageGallery /> : ""}</div>
      <div className="flex-right">
        <h5>Hampden Furnishings</h5>

        <h1 style={{ textAlign: "left", textTransform: "none" }}>
          {series?.seriesName}
        </h1>
        <h3 style={{ textAlign: "left", marginTop: "0", marginBottom: "15px" }}>
          SKU: {currentProduct?.sku}
        </h3>
        <Configurations />
        <p style={{ textAlign: "left" }}>{currentProduct?.longDescription}</p>
        <p style={{ textAlign: "left" }}>
          {currentProduct?.bullets?.length > 0 && (
            <ul>
              {currentProduct.bullets.map((bullet, index) => (
                <li key={index}>{bullet.Bullet}</li>
              ))}
            </ul>
          )}
        </p>
        <p style={{ textAlign: "left" }}>
          {currentProduct?.dims?.length > 0 && (
            <ul>
              {currentProduct.dims.map((dim, index) => (
                <li key={index}>
                  {dim.type}: {dim.dims}
                </li>
              ))}
            </ul>
          )}
        </p>
      </div>
    </div>
  );
};

export default Product;
