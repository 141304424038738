import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { createSearchParams } from "react-router-dom";
import { getPage } from "@cloudinary/url-gen/actions/extract";
import Paginator from "../Components/Media/Paginator";
import ProductTile from "../Components/Collection/ProductTile";
import axios from "axios";

const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const Search = () => {
  const [results, setResults] = useState();
  const [searchParams] = useSearchParams();
  const searchParamsUse = Object.fromEntries([...searchParams]);
  const [dataAmount, setDataAmount] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [perPage, setPerPage] = useState(16);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    document.title = `Search Results for "${searchParamsUse.search}" - National Public Seating`;
  }, [searchParamsUse.search]);

  function handleClick(item) {
    navigate({
      pathname: `/products/${encodeURIComponent(item.series)}`,
      search: `?${createSearchParams(item.configOptions)}`,
    });
  }

  useEffect(() => {
    getPageData(searchParamsUse.search);
    // console.log(pageNumber, "pagenumber", perPage, "perpage");
  }, [pageNumber, perPage]);

  function getTotalDataAmount(searchText) {
    setIsLoading(true);
    axios
      .post(`${api_address}/search/length`, {
        searchText: searchText,
      })
      .then((response) => {
        // console.log(response.data, 45);

        setDataAmount(response.data);
      })
      .catch((error) => {
        console.error("Error fetching total data amount:", error);
      });
  }

  useEffect(() => {
    if (pageNumber + 1 > Math.ceil(dataAmount / perPage)) {
      setPageNumber(Math.ceil(dataAmount / perPage) - 1);
    }
  }, [perPage, pageNumber]);

  function getPageData(searchText) {
    setResults();

    axios
      .post(`${api_address}/search`, {
        searchText: searchText,
        page: pageNumber,
        perPage: perPage,
      })
      .then((response) => {
        // console.log(response.data.results, "this is the response data");
        setIsLoading(false);
        setResults(
          response.data.results?.map((i) => {
            return {
              imageLink: i.imageLink,
              title: i.modelNumber,
              seriesName: i.series,
              configOptions: i.configOptions,
              status: i.status,
            };
          })
        );
      })
      .catch((error) => {
        console.error("Error fetching page data:", error);
        setIsLoading(false);
        return [];
      });
  }

  useEffect(() => {
    getPageData(searchParamsUse.search);
    getTotalDataAmount(searchParamsUse.search);
    setPageNumber(0);
  }, [searchParams]);

  return (
    <>
      <div className="inner-padding collection-page">
        {/* <img
          style={{ width: "100%", objectFit: "cover" }}
          src={`https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${banner}`}
        ></img>  */}
        <h1 style={{ textAlign: "center", fontSize: "24px" }}>
          <span style={{ fontSize: "22px", fontWeight: "400", color: "grey" }}>
            Showing Results for:{" "}
          </span>
          {searchParamsUse.search}
        </h1>

        <div className="underline"></div>
        <div className="products-div">
          {results?.map((i, index) => {
            return <ProductTile key={index} productDetails={i} />;
          })}
        </div>
      </div>

      {!isLoading && Math.ceil(dataAmount / perPage) > 1 ? (
        <div className="pages inner-padding" style={{ display: "flex" }}>
          <Paginator
            currentPage={pageNumber}
            totalPages={Math.ceil(dataAmount / perPage)}
            setCurrentPage={setPageNumber}
            setShow={setPerPage}
            show={perPage}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Search;
