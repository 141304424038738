import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { CgSoftwareDownload } from "react-icons/cg";
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from "@cloudinary/react";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { lazyload } from "@cloudinary/react";
import { AiOutlineLink } from "react-icons/ai";
const MediaTile = ({
  image,
  selectAll,
  addToSelected,
  removeFromSelected,
  index,
  clearList,
  selectedImages,
  gaEventTracker,
  imageEffects,
  applyEffects,
}) => {
  const [selected, setSelected] = useState(selectAll);
  const [copied, setCopied] = useState(false);
  const cld = new Cloudinary({
    cloud: {
      cloudName: "da3rom333",
    },
  });
  useEffect(() => {
    if (selectAll) {
      setSelected(true);
    }
    // console.log(selectAll);
  }, [selectAll]);

  useEffect(() => {
    if (!clearList) {
      setSelected(false);
    }
    // console.log(clearList);
  }, [clearList]);

  useEffect(() => {
    if (selectedImages?.includes(image)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedImages]);

  useEffect(() => {
    if (selectedImages?.includes(image)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, []);
  function handleClick() {
    // setSelected(!selected);
    // console.log(selected);
    if (!selected) {
      addToSelected(image);
    } else {
      removeFromSelected(image);
    }
  }

  const createFilters = async () => {
    if (
      Object.keys(imageEffects)
        .filter((i) => i !== "type")
        .filter((i) => imageEffects[i] !== "").length > 0
    ) {
      var string =
        "/" +
        Object.keys(imageEffects)
          .filter((i) => i !== "type")
          .filter((i) => imageEffects[i] !== "")
          .map((key) => {
            return `${key}_${imageEffects[key]}`;
          })
          .join(",");
      if (!Object.keys(imageEffects).includes("q")) {
        string = string + ",q_auto";
      }
      return string;
    }
    return "";
  };

  async function handleDownloadClick(item, type) {
    let filterString = "";

    if (applyEffects) {
      filterString = await createFilters();
    }

    try {
      let newImage;
      if (
        Object.keys(imageEffects).includes("type") &&
        imageEffects["type"] !== ""
      ) {
        newImage =
          item.substring(0, item.lastIndexOf(".") + 1) + imageEffects["type"];
      } else {
        newImage = item;
      }

      if (type === "download") {
        window.open(
          `https://res.cloudinary.com/da3rom333/image/upload${filterString}/fl_attachment/${newImage}`,
          "_blank"
        );
      } else if (type === "copy") {
        navigator.clipboard.writeText(
          `https://res.cloudinary.com/da3rom333/image/upload${filterString}/${newImage}`
        );
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div
      className="mediaTile"
      style={{
        borderColor: selected ? "var(--lightgreen)" : "#808080",
        cursor: "pointer",
      }}
    >
      <div className="tile-img" onClick={handleClick}>
        <AdvancedImage
          cldImg={cld.image(image).resize(scale().width(250))}
          plugins={[lazyload()]}
        />
      </div>
      {/* {index} */}
      <div className="mediaTile-buttons">
        <div
          onClick={handleClick}
          className={"selection-box"}
          style={{
            borderColor: selected ? "var(--lightgreen)" : "#808080",
          }}
        >
          {selected ? <FaCheck /> : ""}
        </div>
        <div
          className="download-img"
          onClick={() => {
            handleDownloadClick(image, "download");
            gaEventTracker(`${image} downloaded`);
          }}
        >
          <CgSoftwareDownload />
        </div>
        <div
          style={{ position: "relative" }}
          className="download-img"
          onClick={() => {
            setCopied(true);
            handleDownloadClick(image, "copy");
            gaEventTracker(`${image} link copied`);
            setTimeout(() => {
              setCopied(false);
            }, 500);
          }}
        >
          <AiOutlineLink />
          {copied ? (
            <div
              className="notice"
              style={{ position: "absolute", marginLeft: "-32px" }}
            >
              Link Copied
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaTile;
