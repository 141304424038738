import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    value: { value: false },
  },
  reducers: {
    setAuth: (state, action) => {
      state.value = action.payload;
      //   console.log("auth was reset");
    },
  },
});
export const { setAuth } = authSlice.actions;
export const selectAuth = (state) => state.auth.value;
export default authSlice.reducer;
