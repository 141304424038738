import React, { useState } from "react";
import "../../CSS/DealerLogin.css";
import { IoCloseSharp } from "react-icons/io5";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import { useSelector, useDispatch } from "react-redux";
import useLogout from "../../Hooks/useLogout";
import HelpForm from "./HelpForm";
import Registered from "./Registered";
import { setUserInfo } from "../../ReduxSlice/user";
const DealerLogin = () => {
  const { displayBoolean, displayLogin } = useSelector(
    (state) => state.user.value
  );
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.value);
  const logout = useLogout();
  const [showSignup, setShowSignup] = useState();
  const [showHelp, setShowHelp] = useState();
  const [showRegistered, setShowRegistered] = useState();
  const [form, setForm] = useState("");
  function close() {
    dispatch(
      setUserInfo({
        displayBoolean: false,
        username: "",
        password: "",
        displayLogin: true,
      })
    );
  }
  const signOut = async () => {
    await logout();
  };

  return (
    <div className={`dealer-login ${displayBoolean ? "" : "close"}`}>
      <div
        className={`dealer-overlay ${
          displayBoolean ? "show-overlay" : "close"
        }`}
      >
        <div className={`login-popup ${displayBoolean ? "open" : "close"}`}>
          <input
            type="checkbox"
            id="show"
            checked={displayBoolean}
            onChange={(e) => {
              dispatch(setUserInfo({ displayBoolean: e.target.checked }));
            }}
          ></input>
          <input
            type="checkbox"
            id="login"
            checked={displayLogin}
            onChange={(e) => {
              dispatch(setUserInfo({ displayLogin: e.target.checked }));
            }}
          ></input>
          <IoCloseSharp onClick={close} className="close-button" />

          <div className={`inner-login-div${displayLogin ? "" : " reverse"}`}>
            <div
              style={{
                alignSelf:
                  !showRegistered &&
                  !displayLogin &&
                  ((!showSignup && !showHelp) || showHelp)
                    ? "start"
                    : " ",
                marginTop:
                  !showRegistered &&
                  !displayLogin &&
                  ((!showSignup && !showHelp) || showHelp)
                    ? "20px"
                    : "",
                width: showRegistered ? "70%" : " ",
              }}
              className="login-input-div"
            >
              <h2>
                {displayLogin
                  ? auth.rep
                    ? `Welcome ${
                        auth.rep.includes(" ")
                          ? auth.rep.substring(0, auth.rep.indexOf(" "))
                          : auth.rep
                      }!`
                    : "Login"
                  : showRegistered && form === "showHelp"
                  ? "Thank you for reaching out!"
                  : showRegistered && form === "showSignup"
                  ? "Thank you for creating an account and joining Hampden!"
                  : showHelp
                  ? "Let Us Help You!"
                  : "Register"}
              </h2>
              <div className="underline"></div>
              {displayLogin ? (
                auth.rep ? (
                  <button
                    className="submit dealer-button sign-out-submit"
                    onClick={signOut}
                  >
                    SIGN OUT
                  </button>
                ) : (
                  <LoginForm
                    setShowSignup={setShowSignup}
                    setShowHelp={setShowHelp}
                  />
                )
              ) : !showRegistered && !showSignup && !showHelp ? (
                <div className="register-div">
                  <div className="text">
                    Are you an existing Hampden Dealer?
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "30px",
                    }}
                  >
                    <button
                      onClick={() => setShowSignup(true)}
                      className="submit dealer-button"
                    >
                      YES
                    </button>
                    <button
                      onClick={() => setShowHelp(true)}
                      className="submit dealer-button"
                    >
                      NO
                    </button>
                  </div>
                </div>
              ) : showSignup ? (
                <SignupForm
                  setShowRegistered={setShowRegistered}
                  setShowSignup={setShowSignup}
                  setForm={setForm}
                />
              ) : showHelp ? (
                <HelpForm
                  setShowRegistered={setShowRegistered}
                  setShowHelp={setShowHelp}
                  setForm={setForm}
                />
              ) : showRegistered ? (
                <Registered setShowRegistered={setShowRegistered} form={form} />
              ) : (
                " "
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerLogin;
