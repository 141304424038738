import React from "react";
import ReactDOM from "react-dom";
import "./CSS/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './Components/ScrollToTop'
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import authReducer from "./ReduxSlice/auth";
import userReducer from "./ReduxSlice/user";
import productReducer from "./ReduxSlice/product";

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    product: productReducer
  }
})


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ScrollToTop />
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
