import React from "react";
import MediaTile from "./MediaTile";
import { useState, useEffect } from "react";
import Paginator from "./Paginator.js";
const MediaTiles = ({
  list,
  selectAll,
  addToSelected,
  removeFromSelected,
  clearList,
  selectedImages,
  gaEventTracker,
  imageEffects,
  applyEffects,
}) => {
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState();
  const [show, setShow] = useState(16);

  const [flatList, setFlatList] = useState();

  function flattenList() {
    if (list) {
      //   console.log(Object.keys(list));
      var flatArray = [];
      Object.keys(list)?.map((i) => {
        list[i]?.imageLinks?.forEach((j) => {
          flatArray.push({ imageLink: j });
        });
      });
      // console.log(flatArray);
      setFlatList(flatArray);
    }
  }

  useEffect(() => {
    flattenList();
  }, [list]);

  useEffect(() => {
    if (flatList) {
      setLastPage(Math.ceil(flatList.length / show));
    }
    setPage(0);
  }, [flatList, show]);

  return (
    <div className="mediaTiles">
      {flatList ? (
        flatList.slice(show * page, show * page + show).map((i, index) => {
          // console.log(i);
          return (
            <MediaTile
              key={index}
              index={index}
              image={i.imageLink}
              selectAll={selectAll}
              addToSelected={addToSelected}
              removeFromSelected={removeFromSelected}
              clearList={clearList}
              selectedImages={selectedImages}
              gaEventTracker={gaEventTracker}
              imageEffects={imageEffects}
              applyEffects={applyEffects}
            />
          );
        })
      ) : (
        <div>{" Loading"}</div>
      )}
      <div className="pages" style={{ display: "flex" }}>
        {/* {lastPage
          ? Array.from(Array(lastPage + 1).keys()).map((i) => (
              <div className="page">{i + 1}</div>
            ))
          : ""} */}
        {flatList ? (
          <Paginator
            currentPage={page}
            totalPages={lastPage}
            setCurrentPage={setPage}
            setShow={setShow}
            show={show}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MediaTiles;
