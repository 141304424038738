import React from "react";
import { useState, useEffect } from "react";
import "../../CSS/EditableForm.css";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import ImageWithHover from "./ImageWithHover";
import { BsArrowReturnRight, BsCheckLg } from "react-icons/bs";
import { BiChevronDown, BiChevronUp, BiLeftArrow } from "react-icons/bi";
import UploadFile from "../Shared/UploadFile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import InfoPopup from "../Products/InfoPopup";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import axios from "axios";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;
// import useAuth from "../../hooks/useAuth";

//table of editable fields, assuming the data is coming in as an array of objects. save handler should receive an id, key, value to change

//columns expected format
// columns =[{type: (text, dropdown, or boolean), name:'', searchAllowed: true or false, editable: true or false, values: if dropdown}]
const EditableForm = ({
  title,
  columns,
  data,
  saveHandler,
  getData,
  getAllData,
  addHandler = false,
  removeHandler = false,
  exportAvailable = false,
  exportHandler = false,
  exportTemplateHanlder = false,
  batchImportHandler = false,
  uploadImportHandler = false,
  notice = false,
  importBatch = false,
}) => {
  const [editableRows, setEditableRows] = useState([]);
  const [filters, setFilters] = useState();
  const [newData, setNewData] = useState(data);
  const [openAdd, setOpenAdd] = useState(false);
  const [addValues, setAddValues] = useState();
  const [batchImportData, setBatchImportData] = useState();
  const [uploadImportData, setUploadImportData] = useState();
  const [showSelect, setShowSelect] = useState();
  const [roles, setRoles] = useState();
  const [searchRoles, setSearchRoles] = useState([]);
  const [sort, setSort] = useState("ASC");
  const [sortColumn, setSortColumn] = useState();

  useEffect(() => {
    if (batchImportData) {
      batchImportHandler(batchImportData);
      setBatchImportData();
    }
  }, [batchImportData]);

  useEffect(() => {
    if (uploadImportData) {
      uploadImportHandler(uploadImportData);
      setUploadImportData();
    }
  }, [uploadImportData]);

  const handleInputChangeAdd = (event) => {
    const { name, value } = event.target;
    if (addValues) {
      setAddValues((addValues) => ({
        ...addValues,
        [name]: value,
      }));
    } else {
      setAddValues({
        [name]: value,
      });
    }
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const handleEdit = (dataLine) => {
    setEditableRows([...editableRows, dataLine.ID]);
  };

  const handleDateChange = (date, data, field) => {
    if (date) {
      // console.log(date, 85);
      // console.log(date, 85);
      setNewData((prevData) => {
        return prevData.map((obj) => {
          if (obj.ID === data.ID) {
            return {
              ...obj,
              [field]: date,
            };
          }
          return obj;
        });
      });
    }
  };

  const handleInputChange = (event, data, field, booleanType = false) => {
    // console.log(event, data, field, 100, "data in handleinput change");
    setNewData((prevData) => {
      return prevData.map((obj) => {
        // console.log(obj, "object");
        if (obj.ID === data.ID) {
          return {
            ...obj,
            [field]: booleanType
              ? event.target.checked
                ? 1
                : 0
              : event.target.value,
          };
        }
        return obj;
      });
    });
    // console.log(editableRows);
  };
  useEffect(() => {}, [sort, sortColumn]);
  const handleSortChange = (fieldName) => {
    if (fieldName === sortColumn) {
      setSort((prevSortDirection) =>
        prevSortDirection === "ASC" ? "DESC" : "ASC"
      );
    } else {
      setSortColumn(fieldName);
      setSort("ASC");
    }
  };
  // console.log(columns);

  function sortAllRows() {
    if (newData) {
      var rows = [...newData];
      // console.log(sortedRows, "158");

      rows.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        function isValidDate(date) {
          return !isNaN(new Date(date).getTime());
        }
        if (isValidDate(valueA) && isValidDate(valueB)) {
          const dateA = new Date(valueA);
          const dateB = new Date(valueB);

          if (sort === "ASC") {
            return dateA - dateB;
          } else if (sort === "DESC") {
            return dateB - dateA;
          } else {
            return 0;
          }
        } else if (!isNaN(valueA) && !isNaN(valueB)) {
          if (sort === "ASC") {
            return valueA - valueB;
          } else if (sort === "DESC") {
            return valueB - valueA;
          }
        } else {
          if (sort === "ASC") {
            return String(valueA)
              .toLowerCase()
              .localeCompare(String(valueB).toLowerCase());
          } else if (sort === "DESC") {
            return String(valueB)
              .toLowerCase()
              .localeCompare(String(valueA).toLowerCase());
          } else {
            return 0;
          }
        }
      });
      // console.log(sortedRows, 181);
      setNewData(rows);
    }

    // console.log("sortedusers", sortedUsers);
  }

  useEffect(() => {
    sortAllRows();
  }, [sortColumn, sort]);

  function saveRole(ID, role, checked) {
    axios
      .post(`${api_address}/admin/saveRole`, {
        ID: ID,
        role: role,
        checked: checked,
      })
      .then((response) => {
        // console.log(response.data, "response when saving");
        // setNotice("SAVED");
      })
      .catch((err) => {
        console.log(err.sqlMessage);
        // setNotice(`ERROR SAVING: ${err} `);
      });
  }
  const handleRoleChange = (ID, role, checked) => {
    // console.log(ID, role, checked, 155);
    setNewData((prevData) => {
      // console.log(prevData, 159);
      return prevData.map((obj) => {
        // console.log(obj, "object");
        if (obj.ID === ID) {
          return {
            ...obj,
            userRoles: {
              ...obj.userRoles,
              [role]: checked ? 1 : 0,
            },
          };
        }
        return obj;
      });
    });
    saveRole(ID, role, checked);
  };
  function save(dataLine) {
    setEditableRows(
      editableRows.filter((i) => {
        return i !== dataLine.ID;
      })
    );
    // const dataLine = newData.filter((i) => i === dataLine);
    console.log(dataLine);
    saveHandler(dataLine);
  }

  function remove(dataLine) {
    // const dataLine = newData[index];
    setEditableRows(editableRows.filter((i) => i !== dataLine.ID));
    setNewData(newData.filter((i) => i.ID !== dataLine.ID));
    // console.log(id);
    removeHandler(dataLine.ID);
  }

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getUTCFullYear();
    const month = dateObject.getUTCMonth() + 1;
    const day = dateObject.getUTCDate();

    const paddedDay = day < 10 ? `0${day}` : day;
    const paddedMonth = month < 10 ? `0${month}` : month;

    const formattedDate = `${paddedMonth}-${paddedDay}-${year}`;

    return formattedDate;
  };

  useEffect(() => {
    axios
      .post(`${api_address}/admin/roles`)
      .then((response) => {
        // console.log(Object.values(response.data), 219);

        setRoles(Object.values(response.data));
      })
      .catch((err) => {
        console.log(err.sqlMessage);
      });
  }, []);

  function handleSearchRoleChange(role, checked) {
    if (checked) {
      setSearchRoles([...searchRoles, role]);
    } else {
      setSearchRoles(searchRoles.filter((i) => i !== role));
    }
  }

  useEffect(() => {
    // console.log(data, columns, 76);
    setNewData(data);
  }, [data]);

  return (
    <div style={{ minHeight: "747px" }}>
      {notice ? (
        <div
          className="notice-popup"
          style={{
            background: "white",
            color: "#00435B",
            fontWeight: "600",
            // bottom: "15rem",
          }}
        >
          {notice}
        </div>
      ) : (
        ""
      )}

      {openAdd ? (
        <div className="popup">
          <div className="overlay"></div>

          <div
            className="popup-element"
            style={{
              width: "50rem",
              left: "30%",
              borderColor: "white",
              boxShadow: "1px 5px 5px grey",
              position: "fixed",
            }}
          >
            <AiOutlineCloseCircle
              className="close-button"
              onClick={() => {
                setOpenAdd(false);
                setAddValues();
              }}
              style={{ top: "1rem", right: "1rem" }}
            />
            <h2
              style={{
                width: "15rem",
                padding: "0px 17.5rem",
                marginTop: " 30px",
                textAlign: "center",
              }}
            >
              Add New
            </h2>

            <div
              style={{
                overflow: "auto",
                height: "75%",
                width: "75%",
                border: "1px solid",
                margin: "auto",
                marginTop: "2rem",
                display: "flex",
                // justifyContent: "center",
                flexDirection: "column",
                padding: "4rem 2rem",
                boxSizing: "border-box",
              }}
            >
              {columns
                ?.filter((i) => {
                  return i.display;
                })
                ?.filter((i) => i.fieldName !== "ID")

                ?.map((i) => {
                  if (i?.type?.toLowerCase() === "dropdown") {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1rem",
                        }}
                      >
                        <label
                          style={{
                            // width: "10rem",
                            display: "inline-block",
                            fontSize: "18px",
                            textTransform: "uppercase",
                          }}
                        >
                          {i.name}
                        </label>
                        <select
                          onChange={handleInputChangeAdd}
                          value={addValues ? addValues[i.name] : ""}
                          name={i.name}
                          style={{ border: "1px solid", width: "15rem" }}
                        >
                          <option value={""}>Select Option</option>
                          {columns
                            ?.find(
                              (col) =>
                                col.name.toLowerCase() === i.name?.toLowerCase()
                            )
                            ?.values.map((i) => {
                              return <option value={i}>{i}</option>;
                            })}
                        </select>
                      </div>
                    );
                  } else if (i?.type?.toLowerCase() === "datetime") {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1rem",
                        }}
                      >
                        <label
                          style={{
                            // width: "10rem",
                            display: "inline-block",
                            fontSize: "18px",
                            textTransform: "uppercase",
                          }}
                        >
                          {i.name}
                        </label>

                        {/* <DatePicker
                          key={i}
                          onChange={(date) => {
                            // console.log(date, 587);
                            handleDateChangeAdd(date, i.name);
                          }}
                          selected={addValues ? addValues[i.name] : ""}
                        /> */}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1rem",
                        }}
                      >
                        <label
                          style={{
                            // width: "10rem",
                            display: "inline-block",
                            fontSize: "18px",
                            textTransform: "uppercase",
                          }}
                        >
                          {i.name}
                        </label>

                        <input
                          onChange={handleInputChangeAdd}
                          value={addValues ? addValues[i.name] : ""}
                          name={i.name}
                          style={{ border: "1px solid", width: "15rem" }}
                        ></input>
                      </div>
                    );
                  }
                })}
              <button
                className="editable-form-button"
                style={{ marginTop: "2rem", cursor: "pointer" }}
                onClick={() => {
                  setAddValues();
                  setOpenAdd(false);
                  addHandler(addValues);
                  getData();
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="inner-padding">
        <h1>Edit {title}</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "40px 0px",
          }}
        >
          {addHandler ? (
            <button
              className="editable-form-button"
              onClick={() => {
                setOpenAdd(true);
              }}
            >
              Add New
            </button>
          ) : (
            ""
          )}
          {exportAvailable && importBatch ? (
            <button className="editable-form-button" onClick={exportHandler}>
              Export for Batch Update
            </button>
          ) : !importBatch ? (
            <button className="editable-form-button" onClick={exportHandler}>
              Export
            </button>
          ) : (
            ""
          )}
          {importBatch ? (
            <UploadFile
              text={"Import Batch Update"}
              setData={setBatchImportData}
            />
          ) : (
            ""
          )}
          {batchImportData ? (
            <button
              className="editable-form-button"
              onClick={() => {
                batchImportHandler(batchImportData);
              }}
            >
              Import Data
            </button>
          ) : (
            ""
          )}

          {exportTemplateHanlder ? (
            <button
              onClick={exportTemplateHanlder}
              className="editable-form-button"
            >
              Export Upload Template
            </button>
          ) : (
            ""
          )}

          {exportTemplateHanlder ? (
            <UploadFile
              text={"Import Upload Template"}
              setData={setUploadImportData}
            />
          ) : (
            ""
          )}
          {/* {uploadImportData ? (
            <button
              className="editableFormButton"
              onClick={() => {
                uploadImportHandler(uploadImportData);
              }}
            >
              Import Data
            </button>
          ) : (
            ""
          )} */}
        </div>
        <table style={{ margin: "50px 0px" }} className="edit-table">
          <thead>
            <tr>
              <th>Update</th>
              {columns &&
                columns
                  .filter((i) => {
                    return i.display;
                  })
                  .map((i, index) => {
                    switch (i.type.toLowerCase) {
                      case "boolean":
                        return <th key={index}>{i.name}</th>;
                        break;

                      default:
                        return (
                          <th key={index}>
                            <>
                              {sortColumn === i.name && "- "}
                              {i.name}
                              {sortColumn === i.name && " -"}
                              {/* {console.log(i)} */}
                              <br></br>
                              {i.name === "userRoles" ? (
                                <>
                                  <div
                                    onClick={() => {
                                      showSelect === "filterRoles"
                                        ? setShowSelect()
                                        : setShowSelect("filterRoles");
                                    }}
                                    className={`login-input-holder selection `}
                                    style={{
                                      justifyContent: "space-between",
                                      padding: "15px",
                                      display: "flex",
                                      width: "180px",
                                      cursor: "pointer",
                                      fontWeight: "initial",
                                      marginLeft: "2px",
                                    }}
                                  >
                                    Filter By
                                    {showSelect === "filterRoles" ? (
                                      <BiChevronUp
                                        onClick={() => {
                                          setShowSelect();
                                        }}
                                        className="laminate-select-down laminates-svg"
                                      />
                                    ) : (
                                      <BiChevronDown
                                        onClick={() => {
                                          setShowSelect("filterRoles");
                                        }}
                                        className="laminate-select-down laminates-svg"
                                      />
                                    )}{" "}
                                  </div>
                                  {showSelect === "filterRoles" && (
                                    <div
                                      style={{ marginLeft: "2px" }}
                                      className="userroles-dropdown"
                                    >
                                      {roles?.map((role) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            cursor: "default",
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            checked={searchRoles.includes(role)}
                                            onChange={(event) =>
                                              handleSearchRoleChange(
                                                role,
                                                event.target.checked
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                          <div>{role}</div>
                                        </div>
                                      ))}
                                      <div
                                        style={{
                                          display: "flex",
                                          cursor: "default",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={searchRoles.includes(
                                            "No Selections"
                                          )}
                                          onChange={(event) =>
                                            handleSearchRoleChange(
                                              "No Selections",
                                              event.target.checked
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                        <div>No Selections</div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <input
                                  className="filter-input"
                                  type="text"
                                  value={filters ? filters[i.name] : ""}
                                  onChange={(e) =>
                                    handleFilterChange(i.name, e.target.value)
                                  }
                                />
                              )}
                              {sort === "ASC" && sortColumn === i.name ? (
                                <MdKeyboardArrowUp
                                  onClick={() => handleSortChange(i.name)}
                                  style={{
                                    cursor: "pointer",
                                    alignSelf: "center",
                                  }}
                                />
                              ) : (
                                <MdKeyboardArrowDown
                                  onClick={() => handleSortChange(i.name)}
                                  style={{
                                    color: "grey",
                                    cursor: "pointer",
                                    alignSelf: "center",
                                  }}
                                />
                              )}
                            </>
                          </th>
                        );
                    }
                  })}
              {removeHandler ? <th style={{ width: "3%" }}>Remove</th> : ""}
              {/* {clickHandler ? <th>Edit Subitem</th> : ""} */}
            </tr>
          </thead>
          <tbody>
            {newData
              ?.filter((dataIn) => {
                if (searchRoles.length >= 1) {
                  if (
                    Object.values(dataIn["userRoles"]).every((i) => i === 0) &&
                    searchRoles.includes("No Selections")
                  ) {
                    return true;
                  }
                  return Object.keys(dataIn["userRoles"]).some((i) => {
                    const targetValue = dataIn["userRoles"][i];
                    // console.log(userRole, value, targetValue);
                    // console.log(searchRoles, i, 564);
                    return searchRoles.includes(i) && targetValue === 1;
                  });
                } else {
                  return true;
                }
              })
              ?.filter((dataIn) => {
                if (filters) {
                  return Object?.entries(filters)?.every(([key, value]) => {
                    // console.log(key, value, 731);
                    if (value) {
                      // console.log(dataIn[key], 524);
                      return dataIn[key]
                        ?.toString()
                        .toLowerCase()
                        ?.includes(value.toString().toLowerCase());
                    }
                    return true;
                  });
                } else {
                  return true;
                }
              })
              ?.map((dataLine, index) => {
                return (
                  <tr key={index}>
                    <td key={index}>
                      {editableRows?.includes(dataLine.ID) ? (
                        <div>
                          <button
                            style={{ margin: "0px 2px", fontSize: "12px" }}
                            className="editable-form-button"
                            onClick={() => save(dataLine)}
                          >
                            Save
                          </button>

                          <button
                            style={{ margin: "0px 2px", fontSize: "12px" }}
                            className="editable-form-button"
                            onClick={() => {
                              setEditableRows(
                                editableRows.filter((i) => {
                                  // console.log(i, 268);
                                  return i !== dataLine.ID;
                                })
                              );
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <button
                          style={{ fontSize: "12px" }}
                          className="editable-form-button"
                          onClick={() => handleEdit(dataLine)}
                        >
                          Edit
                        </button>
                      )}
                    </td>
                    {columns
                      ?.filter((i) => {
                        return i.display;
                      })
                      ?.map((i, index) => {
                        if (!i.editable) {
                          return <td key={index}>{dataLine[i.name]}</td>;
                        } else {
                          switch (i.type.toLowerCase()) {
                            case "text":
                              return (
                                <td
                                  key={index}
                                  className={`editable-row ${
                                    editableRows?.includes(dataLine.ID)
                                      ? "edit-mode"
                                      : ""
                                  }`}
                                >
                                  {editableRows?.includes(dataLine.ID) ? (
                                    <input
                                      style={{ width: "100%" }}
                                      type="text"
                                      value={dataLine[i.name]}
                                      onChange={(event) =>
                                        handleInputChange(
                                          event,
                                          dataLine,
                                          i.name
                                        )
                                      }
                                    />
                                  ) : (
                                    dataLine[i.name]
                                  )}
                                </td>
                              );
                              break;
                            case "boolean":
                              return (
                                <td
                                  key={index}
                                  className={`editable-row ${
                                    editableRows?.includes(dataLine.ID)
                                      ? "edit-mode"
                                      : ""
                                  }`}
                                >
                                  {editableRows?.includes(dataLine.ID) ? (
                                    <input
                                      type="checkbox"
                                      checked={dataLine[i.name] === 1}
                                      onChange={(event) =>
                                        handleInputChange(
                                          event,
                                          dataLine,
                                          i.name,
                                          true
                                        )
                                      }
                                    />
                                  ) : dataLine[i.name] === 1 ? (
                                    <AiOutlineCheck />
                                  ) : (
                                    <AiOutlineClose />
                                  )}
                                </td>
                              );
                              break;

                            case "roles":
                              return (
                                <td key={index} className={`editable-row`}>
                                  <>
                                    <div
                                      onClick={() => {
                                        showSelect === dataLine.ID
                                          ? setShowSelect()
                                          : setShowSelect(dataLine.ID);
                                      }}
                                      className={`login-input-holder selection `}
                                      style={{
                                        display: "flex",
                                        width: "180px",
                                        justifyContent: "space-between",
                                        padding: "15px",
                                      }}
                                    >
                                      <div className="select-down">
                                        {Object.values(dataLine[i.name]).filter(
                                          (i) => i === 1
                                        ).length === 1
                                          ? Object.keys(
                                              dataLine[i.name]
                                            ).filter(
                                              (key) =>
                                                dataLine[i.name][key] === 1
                                            )
                                          : `${
                                              Object.values(
                                                dataLine[i.name]
                                              ).filter((i) => i === 1).length
                                            } roles selected`}
                                      </div>
                                      {showSelect === dataLine.ID ? (
                                        <BiChevronUp
                                          onClick={() => {
                                            setShowSelect();
                                          }}
                                          className="laminate-select-down laminates-svg"
                                        />
                                      ) : (
                                        <BiChevronDown
                                          onClick={() => {
                                            setShowSelect(dataLine.ID);
                                          }}
                                          className="laminate-select-down laminates-svg"
                                        />
                                      )}
                                    </div>
                                    {showSelect === dataLine.ID ? (
                                      <div className="userroles-dropdown">
                                        {Object.keys(dataLine[i.name]).map(
                                          (role) => (
                                            <div
                                              style={{
                                                display: "flex",
                                                cursor: "default",
                                              }}
                                            >
                                              <input
                                                type="checkbox"
                                                checked={
                                                  dataLine[i.name][role] === 1
                                                }
                                                style={{
                                                  borderRadius: "2px",
                                                  cursor: "pointer",
                                                }}
                                                onChange={(event) =>
                                                  handleRoleChange(
                                                    dataLine.ID,
                                                    role,
                                                    event.target.checked
                                                  )
                                                }
                                              />
                                              <div>{role}</div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}{" "}
                                  </>
                                </td>
                              );
                              break;

                            case "dropdown":
                              return (
                                <td
                                  key={index}
                                  className={`editable-row ${
                                    editableRows?.includes(dataLine.ID)
                                      ? "edit-mode"
                                      : ""
                                  }`}
                                >
                                  {editableRows?.includes(dataLine.ID) ? (
                                    <select
                                      value={dataLine[i.name]}
                                      onChange={(event) =>
                                        handleInputChange(
                                          event,
                                          dataLine,
                                          i.name
                                        )
                                      }
                                    >
                                      {i.values.split(",").map((j) => {
                                        return (
                                          <option value={j} key={j}>
                                            {j}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  ) : (
                                    dataLine[i.name]
                                  )}
                                </td>
                              );
                              break;

                            case "cloudinaryimage":
                              return (
                                <td
                                  key={index}
                                  className={`editable-row ${
                                    editableRows?.includes(dataLine.ID)
                                      ? "edit-mode"
                                      : ""
                                  }`}
                                  //
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      height: "100%",
                                    }}
                                  >
                                    {editableRows?.includes(dataLine.ID) ? (
                                      <input
                                        style={{ width: "100%" }}
                                        type="text"
                                        value={dataLine[i.name]}
                                        onChange={(event) =>
                                          handleInputChange(
                                            event,
                                            dataLine,
                                            i.name
                                          )
                                        }
                                      />
                                    ) : (
                                      dataLine[i]
                                    )}
                                    <ImageWithHover src={dataLine[i.name]} />
                                  </div>
                                </td>
                              );
                              break;

                            case "datetime":
                              return (
                                <td
                                  key={index}
                                  className={`editable-row ${
                                    editableRows?.includes(dataLine.ID)
                                      ? "edit-mode"
                                      : ""
                                  }`}
                                  //
                                >
                                  {" "}
                                  {editableRows?.includes(dataLine.ID) ? (
                                    <DatePicker
                                      key={dataLine.ID}
                                      onChange={(date) => {
                                        // console.log(date, 587);
                                        handleDateChange(
                                          date,
                                          dataLine,
                                          i.name
                                        );
                                      }}
                                      selected={new Date(dataLine[i.name])}
                                    />
                                  ) : (
                                    formatDate(dataLine[i.name])
                                  )}
                                </td>
                              );
                              break;
                          }
                        }
                      })}
                    {removeHandler ? (
                      <td style={{ width: "3%" }}>
                        <AiOutlineCloseCircle
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // console.log(
                            // 	dataLine.ID,
                            // 	dataLine[Object.keys(dataLine)[0]]
                            // );
                            remove(dataLine);
                          }}
                        />
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EditableForm;
