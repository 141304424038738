import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { AdvancedImage, lazyload } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { scale } from "@cloudinary/url-gen/actions/resize";
import axios from "axios";
import FormatImageLink from "../Shared/FormatImageLink";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;
const ProductTile = ({ productDetails }) => {
  const [hover, setHover] = useState(false);
  const [statusIcons, setStatusIcons] = useState([]);
  const decodeAndFormat = FormatImageLink();
  const navigate = useNavigate();
  const cld = new Cloudinary({
    cloud: {
      cloudName: "da3rom333",
    },
  });

  async function getStatusIcons() {
    await axios
      .get(`${api_address}/products/getStatusIcons`)
      .then((response) => {
        setStatusIcons(response.data);
      })
      .catch((error) => {
        console.error("Error fetching status icons:", error);
      });
  }

  useEffect(() => {
    getStatusIcons();
  }, []);
  return (
    <div
      className="product-tile"
      onMouseEnter={() => {
        setHover(true);
        // console.log(productDetails, 19);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        // console.log(productDetails.configOptions, productDetails);
        // navigate(`/products/${productDetails.seriesName.replaceAll("/", "~")}`);
        navigate({
          pathname: `/products/${encodeURIComponent(
            productDetails.seriesName.replaceAll("/", "~")
          )}`,
          search: `?${createSearchParams(productDetails.configOptions)}`,
        });
      }}
    >
      <div className="img-div">
        <AdvancedImage
          cldImg={cld
            .image(productDetails.imageLink)
            .resize(scale().width(450))}
          plugins={[lazyload()]}
          style={{
            transform: hover ? "scale(1.10)" : "",
            transition: "all .2s",
          }}
        />
        {productDetails?.status !== "A" && statusIcons ? (
          <div
            style={{
              height: "4rem",
              marginTop: "-13rem",
              marginLeft: "-11rem",
              position: "absolute",
              color: "white",
              fontSize: "54px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <AdvancedImage
              cldImg={decodeAndFormat(
                statusIcons?.find((i) => i.status === productDetails.status)
                  ?.icon_link
              ).resize(scale().width(170))}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <h3
        style={{
          textDecoration: hover ? "underline" : "none",
          //   textUnderlineOffset: "5px",
        }}
      >
        {productDetails.seriesName}
        <br></br>
        {productDetails?.title && `Model Number: ${productDetails.title}`}
      </h3>
    </div>
  );
};

export default ProductTile;
