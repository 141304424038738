import { useState, useEffect, React } from "react";
import axios from "axios";
import "../../CSS/DealerLogin.css";
import { useNavigate } from "react-router-dom";
const USER_REGEX = /\S+@\S+\.\S+/;
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;
const RESET_PASSWORD_URL = "/auth/requestResetPass";

const RequestReset = () => {
  const [username, setUsername] = useState("");
  const [validUser, setValidUser] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [submit, setSubmit] = useState(false);
  const [userFocus, setUserFocus] = useState(false);
  useEffect(() => {
    setValidUser(USER_REGEX.test(username));
  }, [username]);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    if (validUser) {
      setSubmit(true);
    }
    else {
      setErrMsg('Valid email address is required!');
    }
    e.preventDefault();

    try {
      await axios.post(
        `${api_address}${RESET_PASSWORD_URL}`,
        JSON.stringify({ email: username }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else {
        setErrMsg("Reset Failed");
      }
    }
  };

  return (
    <div className="request-pass-div">
      {!submit ? (
        <div>
          <h1>Forgot your password?</h1>
          <p>
            To reset your password, please enter the email address associated
            with your account below. We will send you an email with instructions
            on how to reset your password.
          </p>
          <form className="pwd-reset login-form">
            <div
              className={`login-input-holder pwd-reset ${userFocus && !validUser
                ? "error-input"
                : validUser
                  ? "valid-input"
                  : ""
                }`}
            >
              <input
                style={{ width: "324px" }}
                type="email"
                name="email"
                placeholder="Email"
                id="email"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                onFocus={() => {
                  setUserFocus(true);
                }}
                required
              ></input>
            </div>
            <div className="required-err-msg">{errMsg}</div>
            <button className="submit dealer-button pwd-reset" type="submit" onClick={handleSubmit}>
              Request Reset Password Link
            </button>
          </form>
        </div>
      ) : (
        <div className="confirmation-div">
          <div
            className="confirmation-msg"
            style={{ textAlign: "center", fontSize: "28px" }}
          >
            An email will be sent to {username} if an account exists. Please
            check spam and trash if you do not see the email within 5 minutes.
          </div>
          <button
            className="submit dealer-button pwd-reset"
            onClick={() => {
              navigate("/");
            }}
          >
            Go Home
          </button>
        </div>
      )}
    </div>
  );
};

export default RequestReset;
