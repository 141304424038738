import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import "../CSS/Collection.css";
import ProductTile from "../Components/Collection/ProductTile";

import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
import axios from "axios";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const Collection = () => {
  const gaEventTracker = useAnalyticsEventTracker("Collections Page");
  useEffect(() => {
    document.title = `${collectionID} - Hampden Furnishings`;
    gaEventTracker(`${collectionID} - Page Visited`);
  }, []);
  const { collectionID } = useParams();
  const [category, setCategory] = useState();
  const [products, setProducts] = useState();
  const [banner, setBanner] = useState();

  async function getBanners() {
    await axios
      .post(`${api_address}/content/getBanners`, {
        page: "category",
      })
      .then((response) => {
        setBanner(response.data.map((i) => i.image_link));
      });
  }

  useEffect(() => {
    getBanners();
    // console.log(banners, 28);
  }, []);

  //get list of items by collectionID
  function getCatData() {
    Axios.post(`${api_address}/products/seriesByCategory`, {
      category: collectionID || "Stack Chairs",
    }).then((response) => {
      // console.log(response.data, 25);
      setProducts(response.data.series);
      setCategory(collectionID);
    });
  }

  useEffect(() => {
    getCatData();
  }, []);

  return (
    <div className="collection-page">
      <img
        style={{ width: "100%", objectFit: "cover" }}
        src={`https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${banner}`}
      ></img>
      <h1>{category}</h1>
      <div className="products-div">
        {products?.map((i, index) => {
          return <ProductTile key={index} productDetails={i} />;
        })}
      </div>
    </div>
  );
};

export default Collection;
