import axios from "axios";
import React, { useEffect, useState } from "react";
import "../CSS/EditorTools.css";
import EditTemplate from "../Components/EditorTools/EditTemplate";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUserInfo } from "../ReduxSlice/user";
import "../CSS/AdminPortal.css";
import UpdateImageTable from "../Components/Admin Portal/UpdateImageTable";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;
const AdminPortal = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState();
  const [warning, setWarning] = useState();
  const auth = useSelector((state) => state.auth.value);
  async function getEditCategories() {
    await axios
      .post(`${api_address}/edit/getEditCategories`, { page: "admin" })
      .then((response) => {
        setCategories(response.data);
        // console.log(response.data);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getEditCategories();
  }, []);

  function handleClick(table) {
    navigate(`/EditTable/${table}`);
  }

  return (
    <>
      <h1>Admin Portal</h1>
      <div className="inner-padding edit-buttons-div">
        {categories?.map((i, index) => {
          return (
            <button
              onClick={() => handleClick(i.table_name)}
              className="edit-button"
              key={index}
            >{`Edit ${i.table_name.replace(/_/g, " ")}`}</button>
          );
        })}
        {auth?.roles?.includes(1117) && (
          <>
            <button
              className="edit-button"
              onClick={() => setWarning("product")}
            >
              Update Product Image Table
            </button>
          </>
        )}
        {warning && (
          <UpdateImageTable setWarning={setWarning} table={warning} />
        )}
      </div>
    </>
  );
};

export default AdminPortal;
