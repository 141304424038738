import { useLocation, useNavigate } from "react-router-dom";

import "../../CSS/Unauthorized.css";

import { useEffect, useState } from "react";
import axios from "axios";
import { setUserInfo } from "../../ReduxSlice/user";
import { useSelector, useDispatch } from "react-redux";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
const banner =
  "https://res.cloudinary.com/da3rom333/image/upload/v1701719174/Hampden%20Assets/banners/Unauthorized_Page_text_kwiq6u.jpg";
const genericBanner =
  "https://res.cloudinary.com/da3rom333/image/upload/v1701719172/Hampden%20Assets/banners/Unauthorized_Page_yyrj1z.jpg";
const Unauthorized = ({ allowedRoles }) => {
  const goBack = () => navigate(-1);
  const { displayBoolean, displayLogin } = useSelector(
    (state) => state.user.value
  );
  const auth = useSelector((state) => state.auth.value);
  const dispatch = useDispatch();
  const authorized = auth?.roles?.find((role) => allowedRoles?.includes(role));
  const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

  const [requested, setRequested] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const requestAccess = async () => {
    const response = await axios.post(
      `${api_address}/auth/accessRequest`,
      JSON.stringify({
        user: auth.rep,
        pageRequest: location.state.from.pathname,
      }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );

    if (response.status === 200) {
      setRequested(true);
    }
  };

  function open() {
    dispatch(
      setUserInfo({
        displayBoolean: true,
        username: "",
        password: "",
        displayLogin: true,
      })
    );
  }
  useEffect(() => {
    console.log(auth, 47);
  }, [auth]);

  return (
    <div className="unauthorized">
      {auth?.roles?.includes(9999) ? (
        <div className="banner">
          <img style={{ width: "100%" }} src={banner}></img>
          <div
            className="buttons"
            style={{ marginTop: "30rem", left: "47.5%" }}
          >
            <a style={{ fontSize: "18px" }} href="/">
              Go Home
            </a>
          </div>
        </div>
      ) : auth?.roles?.length === 0 ? (
        <div className="banner">
          <img style={{ width: "100%" }} src={genericBanner}></img>
          <div className="buttons" style={{ left: "30%", top: "51rem" }}>
            <div
              style={{
                textAlign: "center",
                letterSpacing: "0px",

                fontSize: "45px",
                lineHeight: "56px",
                fontWeight: "bold",
                marginBottom: "15px",
              }}
            >
              Restricted access
            </div>
            <div
              style={{
                textAlign: "center",
                letterSpacing: "0px",
                color: "lightgray",
                width: "760px",
                fontSize: "26px",
                lineHeight: "33px",
                fontWeight: "500",
              }}
            >
              Thank you for your patience while your Hampden dealer account
              request is being reviewed. We will get back to you shortly!
            </div>
            <a
              style={{
                marginTop: "20px",
                fontSize: "17px",
                textDecoration: "underline",
              }}
              href="/"
            >
              Go Home
            </a>
          </div>
        </div>
      ) : !requested ? (
        <div className="banner">
          <img style={{ width: "100%" }} src={banner}></img>
          <div
            style={{ marginLeft: !auth?.rep ? "3rem" : "", marginTop: "31rem" }}
            className="buttons"
          >
            {!auth?.rep ? (
              <button onClick={open}>Login</button>
            ) : (
              <>
                <button onClick={requestAccess}>Request Access</button>
              </>
            )}
            <a style={{ fontSize: "17px" }} href="/">
              Go Home
            </a>
          </div>
        </div>
      ) : (
        <div className="banner">
          <div
            className="buttons"
            style={{ left: "45rem", marginTop: "-3rem" }}
          >
            <IoMdCheckmarkCircleOutline
              style={{ width: "70px", height: "70px" }}
            />
            <h1>Request Sent!</h1>
            <p>Our team will review your request and contact back.</p>
            <button onClick={() => navigate("/")}>Go Home</button>
          </div>
        </div>
      )}
      {/* <div style={{ height: "50rem" }}></div> */}
    </div>
  );
};

export default Unauthorized;
