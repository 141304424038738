import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../ReduxSlice/user";

const Registered = ({
  setShowRegistered,
  form,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clicked = () => {
    dispatch(setUserInfo({ displayBoolean: false, displayLogin: true }));
    setShowRegistered(false);
    navigate("/");
  };
  return (
    <div style={{ marginTop: "30px" }} className="register-div">
      {form === "showSignup" ? (
        <p className="need-account">
          Our team will review your application and send you an e-mail to
          activate your account
        </p>
      ) : form === "showHelp" ? (
        <p className="need-account">
          Our team will review your request and get back to you as soon as
          possible
        </p>
      ) : (
        " "
      )}
      <button onClick={clicked} className="submit" style={{ width: '14rem' }}>
        Go To Home Page
      </button>
    </div>
  );
};

export default Registered;
