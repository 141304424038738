import React, { useEffect } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Cloudinary } from "@cloudinary/url-gen";

const MediaButtons = ({
  selectAll,
  setSelectAll,
  selectedList,
  mediaList,
  setSelectedList,
  setClearList,
  gaEventTracker,
  imageEffects,
  applyEffects,
  setApplyEffects,
}) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: "da3rom333",
    },
  });

  const createFilters = async () => {
    if (
      Object.keys(imageEffects)
        .filter((i) => i !== "type")
        .filter((i) => imageEffects[i] !== "").length > 0
    ) {
      var string =
        "/" +
        Object.keys(imageEffects)
          .filter((i) => i !== "type")
          .filter((i) => imageEffects[i] !== "")
          .map((key) => {
            return `${key}_${imageEffects[key]}`;
          })
          .join(",");
      if (!Object.keys(imageEffects).includes("q")) {
        string = string + ",q_auto";
      }
      return string;
    }
    return "";
  };

  async function handleDownloadClick() {
    gaEventTracker(`Images Downloaded`);

    let filterString = "";

    if (applyEffects) {
      filterString = await createFilters();
    }

    const zip = new JSZip();
    const folder = zip.folder("Images.zip"); // folder name where all files will be placed in

    try {
      // Initiate all fetch requests simultaneously
      await Promise.all(
        selectedList.map(async (item) => {
          let newImage;
          if (
            Object.keys(imageEffects).includes("type") &&
            imageEffects["type"] !== ""
          ) {
            newImage =
              item.substring(0, item.lastIndexOf(".") + 1) +
              imageEffects["type"];
          } else {
            newImage = item;
          }
          const response = await fetch(
            `https://res.cloudinary.com/da3rom333/image/upload${filterString}/fl_attachment/${newImage}`
          );

          if (response.ok) {
            const blob = await response.blob();
            const name = newImage.substring(newImage.lastIndexOf("/") + 1);

            folder.file(name, blob);
          } else {
            throw new Error(response.statusText);
          }
        })
      );

      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "Images.zip");
    } catch (error) {
      console.error("Error generating the zip file:", error);
    }
  }
  // }

  //   selectedList.forEach((url) => {
  //     const blobPromise = fetch(
  //       `https://res.cloudinary.com/da3rom333/image/upload/fl_attachment/${url}`
  //     ).then((r) => {
  //       // console.log(r);
  //       if (r.status === 200) return r.blob();
  //       return Promise.reject(new Error(r.statusText));
  //     });
  //     //   const name = url?.substring(url.lastIndexOf("/") + 1);
  //     const name = url?.substring(url?.split("/", 3).join("/")?.length + 1);
  //     folder.file(name, blobPromise);
  //   });

  //   zip
  //     .generateAsync({ type: "blob" })
  //     .then((blob) => saveAs(blob, "Images.zip"));
  // }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setSelectAll(false);
  //   }, 500);
  //   return () => clearTimeout(timer);
  // }, [selectAll]);

  return (
    <div className="media-download-buttons">
      <button
        className={`option`}
        onClick={() => {
          setSelectAll(!selectAll);
          let holderArray = [];

          if (!selectAll) {
            Object.values(mediaList).forEach((item) => {
              item.imageLinks.forEach((i) => {
                holderArray.push(i);
              });
            });
            // console.log(holderArray, mediaList);
            setSelectedList(holderArray);
          } else {
            setSelectedList();
          }
        }}
      >
        Select All
      </button>
      <button
        className="option"
        onClick={() => {
          //   console.log("here");
          setClearList(true);
          setSelectedList([]);
          setSelectAll(false);
          setTimeout(() => {
            setClearList(false);
          }, 500);
        }}
        style={{}}
      >
        Clear Selection
      </button>
      <button
        className={`option ${
          selectedList ? (selectedList?.length ? "" : "inactive") : ""
        }`}
        onClick={handleDownloadClick}
      >
        Download Selected ({selectedList ? selectedList.length : 0})
      </button>
    </div>
  );
};

export default MediaButtons;
