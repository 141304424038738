import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        value: {
            displayBoolean: false,
            displayLogin: true,
            username: '',
            password: ''
        }
    },
    reducers: {
        setUserInfo: (state, action) => {
            state.value = { ...state.value, ...action.payload }
            // console.log('user info was reset');
        },

    }
});
export const { setUserInfo } = userSlice.actions;
export default userSlice.reducer;