import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    series: '',
    allProducts: [],
    currentProduct: false,
    allOptions: {}, //{config categories: [options for that category]}
    optionsSelected: false, //{config categories: option selected from that category}
    skusAvailable: [], //[each sku is an {config categories: option}]
    optionsAvailable: {}
}

export const productSlice = createSlice({
    name: "product",
    initialState: {
        value: initialState,
    },
    reducers: {
        //set any value of the state
        setProduct: (state, action) => {
            state.value = { ...state.value, ...action.payload };
            if ("allProducts" in action.payload || (action.payload.hasOwnProperty("optionsSelected") && action.payload["optionsSelected"] === false)) {
                state.value = {
                    ...state.value,
                    skusAvailable: action.payload.allProducts.map((product) => product.configurations)
                }
            }
        },
        setAvailableOptions: (state, action) => {
            const { allOptions, skusAvailable, optionsSelected } = state.value;
            let updatedOptions = {};
            //go through all the different configuration categories
            for (const configType in allOptions) {
                let configTypeOptions = [];
                //go through all the available skus 
                skusAvailable?.forEach(availableSku => {
                    let include = true;
                    //check which of the available options includes the options that are already chosen
                    const keys = Object.keys(optionsSelected);
                    for (const k of keys) {
                        if (availableSku.hasOwnProperty(k)) {
                            //only test if this option doesn't match the options selected if it is not the same option type as configType
                            if (configType !== k) {
                                if (!(k in availableSku) || optionsSelected[k] !== availableSku[k]) {
                                    include = false;
                                }
                            }
                        }
                    }
                    if (include) {
                        if (!configTypeOptions.includes(availableSku[configType])) {
                            configTypeOptions.push(availableSku[configType])
                        }
                    }
                    updatedOptions = {
                        ...updatedOptions,
                        [configType]: configTypeOptions
                    }
                })
            }
            state.value = {
                ...state.value,
                optionsAvailable: updatedOptions
            }
        },
        //reset the product state to initial values
        resetProductState: (state) => {
            state.value = { ...initialState }
        },
    },

});

export const { setProduct, setAvailableOptions, resetProductState } = productSlice.actions;
export default productSlice.reducer;

