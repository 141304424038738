import React, { useEffect, useState } from "react";
import "../CSS/Contact.css";
import Axios from "axios";

import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const Contact = () => {
  const gaEventTracker = useAnalyticsEventTracker("Contact Page");
  useEffect(() => {
    document.title = `Contact Us - Hampden Furnishings`;
    gaEventTracker("Contact Us - Page Visited");
  }, []);

  const email_regex = /^\w+@[a-zA-Z_-]+?\.[a-zA-Z]{2,3}$/;
  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    phone: "",
    comment: "",
  });

  const [errMsg, setErrMsg] = useState("");
  const [succes, setSuccess] = useState(false);

  function sendEmail() {
    Axios.post(`${api_address}/contactUs`, {
      customerName: inputValues.name,
      customerEmail: inputValues.email,
      customerPhone: inputValues.phone,
      customerComment: inputValues.comment,
    }).then((response) => {
      // console.log(response.data);
    });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [succes]);

  function resetFields() {
    setInputValues({
      name: "",
      email: "",
      phone: "",
      comment: "",
    });
  }

  function checkFields() {
    return inputValues.email.match(email_regex);
  }

  function handleChange(evt) {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setInputValues({
      ...inputValues,
      [evt.target.name]: value,
    });
  }
  function submitForm(e) {
    e.preventDefault();
    const check = checkFields();
    if (check) {
      sendEmail();
      resetFields();
      setSuccess(true);
      setErrMsg();
      gaEventTracker("Contact Form Submitted");
    } else {
      setErrMsg("Invalid email address.");
    }
  }

  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <h3>Contact us for any questions you have!</h3>
      <form>
        <input
          type="text"
          name="name"
          id="name"
          value={inputValues.name}
          onChange={handleChange}
          placeholder="Name"
        ></input>
        <input
          type="email"
          name="email"
          id="email"
          value={inputValues.email}
          onChange={handleChange}
          placeholder="Email *"
          required
        ></input>
        <input
          type="text"
          name="phone"
          id="phone"
          value={inputValues.phone}
          onChange={handleChange}
          placeholder="Phone number"
        ></input>
        <textarea
          type="textarea"
          name="comment"
          id="comment"
          value={inputValues.comment}
          onChange={handleChange}
          placeholder="Comment"
        ></textarea>
        <button className="square-button-black" onClick={submitForm}>
          Send
        </button>
      </form>

      <div className="errMsg">
        {succes
          ? "Thank you for your submission. We will reach out as soon as possible"
          : errMsg
            ? errMsg
            : ""}
      </div>
    </div>
  );
};

export default Contact;
