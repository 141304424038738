import React, { useEffect, useState } from "react";
import CategoriesSections from "../Components/Home/CategoriesSections";
import Slider from "../Components/Home/Slider";
import { HiPlay } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
import axios from "axios";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;
const Home = ({}) => {
  useEffect(() => {
    document.title = "Hampden Furnishings";
    gaEventTracker("Home - Page Visited");
  }, []);
  const [banner, setBanner] = useState(false);
  async function getBanners() {
    await axios
      .post(`${api_address}/content/getBanners`, {
        page: "home_center",
      })
      .then((response) => {
        setBanner(response.data.map((i) => i.image_link));
      });
  }

  useEffect(() => {
    getBanners();
    // console.log(banners, 28);
  }, []);

  const [play, setPlay] = useState(false);
  const navigate = useNavigate();
  const gaEventTracker = useAnalyticsEventTracker("Home Page");
  return (
    <div className="home-page">
      <Slider />
      <div className="cat-title">
        <h2>Product Categories</h2>
        <h3>View our products and find the best one for you.</h3>
      </div>
      <CategoriesSections />
      <img
        style={{ width: "100%", height: "720px", objectFit: "cover" }}
        src={`https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${banner}`}
      ></img>
      <div className="about-section">
        <h1>About Us</h1>
        <p className="about-p">
          Inspired by the quintessential Baltimore, MD neighborhood, Hampden
          Furnishings produces furniture with the same quality and attention to
          detail that you’d find at Hampdens main drag, 36th Street aka “The
          Avenues” vintage shoppes and art galleries.
        </p>
        <button
          className="square-button-white"
          onClick={() => {
            gaEventTracker("Home Page Direct to About Page");
            navigate("/about");
          }}
        >
          Get To Know Us
        </button>
      </div>
      <div className="video-home-div">
        <h2>Video</h2>

        {play ? (
          <iframe
            width="1100px"
            height="587px"
            src="https://www.youtube.com/embed/DX3StZfpKTA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        ) : (
          <div>
            <img
              src="https://res.cloudinary.com/da3rom333/image/upload/v1700598428/Hampden%20Assets/banners/Video_Preview_Home_1_ikjjre.png"
              style={{ width: "100%" }}
            ></img>
            <div
              className="play-video"
              onClick={(e) => {
                e.preventDefault();
                setPlay(true);
                gaEventTracker("Home Page Video Played");
              }}
            >
              <HiPlay />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
