import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AdvancedImage, lazyload } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { scale } from "@cloudinary/url-gen/actions/resize";
const CategoryTile = ({ category, pointer, index }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: "da3rom333",
    },
  });
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      className={`category-tile ${pointer === index ? "show" : ""}`}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        navigate(`/collections/${category.category}`);
      }}
    >
      <div className="cat-img-div">
        <AdvancedImage
          cldImg={cld.image(category.imageLink).resize(scale().width(150))}
          plugins={[lazyload()]}
        />
      </div>
      <h2 style={{ fontSize: "18px" }}>{category.category}</h2>
      <h3
        style={{
          display: "flex",
          alignItems: "center",

          width: "7rem",
          justifyContent: "center",
          fontWeight: hover ? "600" : "400",
        }}
      >
        See More{" "}
        <BsArrowRight
          style={{
            strokeWidth: hover ? "1px" : ".25px",
            marginLeft: ".5rem",
          }}
        />
      </h3>
    </div>
  );
};

export default CategoryTile;
