import React, { useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
const Paginator = ({
  currentPage,
  totalPages,
  setCurrentPage,
  show,
  setShow,
}) => {
  const [jumpPage, setJumpPage] = useState();
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  }
  return (
    <div
      className="flex-page"
      style={{ justifyContent: "space-between", width: "100%" }}
    >
      <div className="flex-page">
        <div
          onClick={() => {
            if (currentPage !== 0) {
              setCurrentPage(currentPage - 1);
              scrollToTop();
            }
          }}
          style={{
            color: currentPage === 0 ? "#acacac" : "#808080",
            cursor: currentPage === 0 ? "default" : "cursor",
            marginRight: "10px",
          }}
          className="paginator-text navigate-page"
        >
          <IoIosArrowBack />
          Previous
        </div>
        <div className="paginator-text" style={{ cursor: "default" }}>
          Page {currentPage + 1} of {totalPages}
        </div>
        <div
          onClick={() => {
            if (currentPage !== totalPages - 1) {
              setCurrentPage(currentPage + 1);
              scrollToTop();
            }
          }}
          style={{
            color: currentPage === totalPages - 1 ? "#acacac" : "#808080",
            cursor: currentPage === totalPages - 1 ? "default" : "pointer",
            marginLeft: "10px",
          }}
          className="paginator-text navigate-page"
        >
          Next
          <IoIosArrowForward />
        </div>
      </div>
      <div className="paginator-text setshowqty">
        Show{" "}
        <div
          className={`paginator-text ${show === 16 ? "current" : ""}`}
          onClick={() => {
            setShow(16);
          }}
        >
          16
        </div>
        <div
          className={`paginator-text ${show === 32 ? "current" : ""}`}
          onClick={() => {
            setShow(32);
          }}
        >
          32
        </div>
        <div
          className={`paginator-text ${show === 64 ? "current" : ""}`}
          onClick={() => {
            setShow(64);
          }}
        >
          64
        </div>
      </div>
      <div className="flex-page">
        <div className="paginator-text" style={{ cursor: "default" }}>
          Jump to page{" "}
          <input
            value={jumpPage}
            onChange={(e) => {
              setJumpPage(e.target.value);
            }}
          ></input>
        </div>
        <div
          className="paginator-text navigate-page"
          style={{
            color:
              jumpPage > 0 && jumpPage <= totalPages ? "#808080" : "#acacac",
            cursor:
              jumpPage > 0 && jumpPage <= totalPages ? "pointer" : "default",
            marginLeft: "10px",
          }}
          onClick={() => {
            if (jumpPage > 0 && jumpPage <= totalPages) {
              setCurrentPage(jumpPage - 1);
              scrollToTop();
            }
          }}
        >
          Go
          <IoIosArrowForward />
        </div>
      </div>
    </div>
  );
};

export default Paginator;
