import React, { useEffect, useState } from "react";
import { BsChevronDown, BsPerson, BsPersonCircle } from "react-icons/bs";
import { HiArrowLongRight, HiArrowLongLeft } from "react-icons/hi2";
import "../../CSS/Header.css";
import { useRef } from "react";
import Axios from "axios";
import { HiOutlineMenu, HiOutlineUserCircle } from "react-icons/hi";
import { TfiClose, TfiSearch } from "react-icons/tfi";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import DealerLogin from "../Login/DealerLogin";
import { useSelector, useDispatch } from "react-redux";

import useLogout from "../../Hooks/useLogout";
import { setUserInfo } from "../../ReduxSlice/user";
import { CiSearch } from "react-icons/ci";
import { IoSearchOutline } from "react-icons/io5";
import { PiUserCircleThin, PiUserThin } from "react-icons/pi";

import "../../CSS/Search.css";

const Header = () => {
  const auth = useSelector((state) => state.auth.value);

  const dispatch = useDispatch();
  const logout = useLogout();
  const displayBoolean = useSelector(
    (state) => state.user.value.displayBoolean
  );

  const [loginHover, setLoginHover] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [categories, setCategories] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef(null);
  const navigate = useNavigate();
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    // return () => {
    //   document.removeEventListener("click", handleClickOutside, true);
    // };
  }, []);
  const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;
  function getData() {
    Axios.get(`${api_address}/products/getCategories`, {
      // collectionID: collectionID,
    }).then((response) => {
      // console.log(response.data);
      setCategories(response.data);
    });
  }

  function handleSearch(e) {
    if (e.key === "Enter") {
      navigate({
        pathname: `/search`,
        search: `?search=${searchText}`,
      });

      toggleSearch();
      setSearchText("");
    }
  }

  function toggleSearch() {
    var input = document.getElementById("searchInput");
    input.classList.toggle("show");
    if (input.classList.contains("show")) {
      document.getElementById("inputField").focus(); // To focus on the input after it slides out
    } else {
      if (searchText) {
        navigate({
          pathname: `/search`,
          search: `?search=${searchText}`,
        });
      }
      setSearchText("");
    }
  }

  const signOut = async () => {
    await logout();
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    // console.log(categories);
  }, [categories]);
  return (
    <div>
      <div style={{ height: "6rem" }}></div>

      <div className="header-div">
        <div className="header">
          <div>
            <img
              className="logo"
              src="https://cdn.shopify.com/s/files/1/0606/1785/1068/files/logo_web_Hampden_Mesa_de_trabajo_1_Mesa_de_trabajo_1_140x.png?v=1643812878"
              onClick={() => {
                navigate("/");
              }}
            ></img>
            <div className={`nav-list`}>
              <div className="nav-item">
                <a href="/">Home</a>
              </div>
              <div className="nav-item-products" ref={ref}>
                <div style={{ position: "relative" }}>
                  <div
                    onClick={() => {
                      setDropdown(!dropdown);
                    }}
                  >
                    Products <BsChevronDown />
                  </div>
                  {dropdown ? (
                    <div className="product-drop">
                      {categories?.map((i, index) => {
                        return (
                          <a key={index} href={`/collections/${i.category}`}>
                            {i.category}
                          </a>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="nav-item">
                <a href="/media">Media</a>
              </div>
              <div className="nav-item">
                <a href="/about">About Us</a>
              </div>
              <div className="nav-item">
                <a href="/contact">Contact</a>
              </div>
              {auth?.roles?.includes(1984) && (
                <div>
                  <a className="permission-nav-item" href="/editTools">
                    Editor Tools
                  </a>
                </div>
              )}
              {auth?.roles?.includes(5150) && (
                <div>
                  <a className="permission-nav-item" href="/adminPortal">
                    Admin
                  </a>
                </div>
              )}

              <div className="search-white-box"></div>

              <div style={{ position: "relative" }}>
                <div
                  style={{ position: "relative", zIndex: 2 }}
                  className="search-icon"
                  onClick={toggleSearch}
                >
                  <CiSearch
                    style={{
                      color: "var(--darkgrey)",
                      // position: "absolute",
                      width: "25px",
                      height: "25px",
                      verticalAlign: "bottom",
                      // marginTop: "0.4rem",
                      // marginLeft: "24rem",
                    }}
                  />
                </div>
                <div className="search-input" id="searchInput">
                  <input
                    onKeyDown={(e) => handleSearch(e)}
                    type="text"
                    placeholder="Search..."
                    id="inputField"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
                <div className="search-input-hide"></div>
              </div>

              <div
                className="nav-item"
                style={{ zIndex: 2 }}
                onClick={() => {
                  dispatch(setUserInfo({ displayBoolean: !displayBoolean }));
                }}
              >
                <PiUserThin
                  style={{
                    width: "26px",
                    height: "26px",

                    verticalAlign: "bottom",
                  }}
                />
                {/* {auth.rep
                  ? `Welcome ${auth.rep.substring(0, auth.rep.indexOf(" "))}!`
                  : "SIGN IN"} */}
              </div>
            </div>
          </div>
          {/* <div className="search">
            <TfiSearch />
          </div> */}
        </div>

        <div className="header-small-screen">
          <div
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          >
            {showMenu ? <TfiClose /> : <HiOutlineMenu />}
          </div>
        </div>
        {showMenu ? (
          <div className="menu-small-screen">
            {auth.rep && (
              <div
                className="nav-item bold-header-button"
                onClick={() => {
                  dispatch(setUserInfo({ displayBoolean: !displayBoolean }));
                  setShowMenu(!showMenu);
                }}
              >
                Welcome {auth.rep.substring(0, auth.rep.indexOf(" "))}!
              </div>
            )}
            <div className="nav-item">
              <a href="/">Home</a>
            </div>
            <div className="nav-item" ref={ref}>
              <div
                onClick={() => {
                  setDropdown(!dropdown);
                }}
              >
                Products <HiArrowLongRight style={{ marginLeft: "1rem" }} />
              </div>
              {dropdown ? (
                <div className="product-drop">
                  <div
                    onClick={() => {
                      setDropdown(false);
                    }}
                  >
                    <HiArrowLongLeft style={{ marginRight: "1rem" }} /> Products
                  </div>
                  {categories?.map((i, index) => {
                    return (
                      <a key={index} href={`/collections/${i.category}`}>
                        {i.category}
                      </a>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="nav-item">
              <a href="/media">Media</a>
            </div>
            <div className="nav-item">
              <a href="/about">About Us</a>
            </div>
            <div className="nav-item">
              <a href="/contact">Contact</a>
            </div>
            {auth?.roles?.includes(1984) && (
              <div className="nav-item">
                <a href="/editTools">Editor Tools</a>
              </div>
            )}
            {auth?.roles?.includes(5150) && (
              <div className="nav-item">
                <a href="/adminPortal">Admin</a>
              </div>
            )}
            {!auth.rep && (
              <div
                className="nav-item bold-header-button"
                onClick={() => {
                  dispatch(setUserInfo({ displayBoolean: !displayBoolean }));
                  setShowMenu(!showMenu);
                }}
              >
                SIGN IN
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <DealerLogin />
    </div>
  );
};

export default Header;
