import React from "react";
import { Cloudinary } from "@cloudinary/url-gen";

const cld = new Cloudinary({
  cloud: {
    cloudName: "da3rom333",
  },
});

const FormatImageLink = () => {
  function decodeAndFormat(imgLink) {
    let newImageLink = imgLink;
    if (newImageLink?.toString()?.toLowerCase()?.includes("upload")) {
      const uploadIndex = newImageLink?.indexOf("/upload/");
      if (uploadIndex !== -1) {
        const trimmedURL = newImageLink?.substring(
          uploadIndex + "/upload/".length
        );
        const decodedURL = decodeURIComponent(trimmedURL);
        newImageLink = decodedURL;
      }
    }
    return cld.image(newImageLink);
  }

  return decodeAndFormat;
};

export default FormatImageLink;
