import axios from '../api/axios';
//import useAuth from './useAuth';
import { useDispatch } from "react-redux";
import { setAuth } from "../ReduxSlice/auth";
import { useSelector } from "react-redux";

const useRefreshToken = () => {
    //const { setAuth } = useAuth();
    const auth = useSelector((state) => state.auth.value);
    const dispatch = useDispatch();
    const refresh = async () => {
        const response = await axios.get('/refresh', {
            withCredentials: true
        });

        // setAuth(prev => {
        //     console.log('prev', prev);
        //     return {
        //         ...prev,
        //         roles: response.data.roles,
        //         accessToken: response.data.accessToken,
        //         rep: response.data.rep,
        //         dealer: response.data.dealer,
        //         phone: response.data.phone
        //     }
        // });
        const newAuth = {
            ...auth,
            roles: response.data.roles,
            accessToken: response.data.accessToken,
            rep: response.data.rep,
            dealer: response.data.dealer,
            phone: response.data.phone
        }
        console.log('------>', newAuth);
        dispatch(setAuth({
            ...auth,
            roles: response.data.roles,
            accessToken: response.data.accessToken,
            rep: response.data.rep,
            dealer: response.data.dealer,
            phone: response.data.phone
        }
        ));
        //dispatch(setAuth({ ...newAuth }));
        return response.data.accessToken
    }
    return refresh

}

export default useRefreshToken;
