import React, { useEffect, useState } from "react";
// import useAxiosJWT from "../../hooks/useAxiosJWT";
// import EditableForm from "./EditableForm";
import { useNavigate, useParams } from "react-router-dom";
import useExportToExcel from "../Shared/useExportToExcel";
// import ImageWithHover from "./ImageWithHover";
import { MdKeyboardArrowUp } from "react-icons/md";
import axios from "axios";
import EditableForm from "./EditableForm";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const EditTemplate = ({
  getEndPoint,
  table,
  title,
  searchValue = false,
  exportAvailable = false,
  importBatch = false,
  exportTemplate = false,
  importUpdate = false,
  exportEndPoint = false,
  specificColumn = false,
  specification = false,
  allowAdd = false,
}) => {
  const [data, setData] = useState();
  const [search, setSearch] = useState();
  //   const axiosJWT = useAxiosJWT();
  const navigate = useNavigate();
  const [notice, setNotice] = useState();
  const [columns, setColumns] = useState();

  useEffect(() => {
    getColumns();
  }, []);

  function getData() {
    if (getEndPoint === "getGenericData") {
      axios
        .post(`${api_address}/edit/getGenericData`, {
          table: table,
          specificColumn: specificColumn,
          specification: `'${specification}'`,
        })
        .then((response) => {
          // console.log(response.data, 41);
          setData(response.data);
        });
    } else {
      axios.post(`${api_address}/admin/${getEndPoint}`).then((response) => {
        // console.log(response.data, 41);
        setData(response.data);
      });
    }
  }

  async function getColumns() {
    // console.log(table, getEndPoint, 46);

    try {
      const response = await axios.post(`${api_address}/edit/getColumns`, {
        table,
      });

      //   console.log(response.data, 51);
      setColumns(response.data);
    } catch (error) {
      console.error("Error fetching columns:", error);
    }
  }

  const ExportToExcel = useExportToExcel();
  function exportHandler() {
    if (getEndPoint === "getGenericData") {
      axios
        .post(`${api_address}/edit/getGenericData`, {
          table: table,
        })
        .then((response) => {
          ExportToExcel(
            {
              data: response.data,
            },
            table
          );
        });
    } else {
      axios
        .post(`${api_address}/admin/${getEndPoint}`, {
          table: table,
        })
        .then((response) => {
          ExportToExcel(
            {
              data: response.data,
            },
            table
          );
        });
    }
  }

  function findIDField(value) {
    const table = columns.find(
      (col) => col.name?.toLowerCase() === value?.toLowerCase()
    ).table_name;

    const name = columns.find(
      (col) =>
        col.table_name.toLowerCase() === table.toLowerCase() &&
        col.fieldName.toLowerCase() === "id"
    ).name;

    return name;
  }
  function batchImportHandler(data) {
    // console.log(data, 104);
    axios
      .post(`${api_address}/edit/replaceTableData`, {
        table: table,
        data: data.filter((row) => {
          return Object.keys(row).some(
            (value) =>
              row[value] !== null &&
              row[value] !== "" &&
              row[value] !== undefined
          );
        }),
        columns: columns,
      })
      .then((response) => {
        // console.log(response);
        setNotice("Batch Imported");
      })
      .catch((err) => {
        setNotice(err);
      });
  }

  function uploadImportHandler(data) {
    axios
      .post(`${api_address}/edit/addTableData`, {
        table: table,
        data: data.filter((row) => {
          return Object.keys(row).every(
            (value) =>
              row[value] !== null &&
              row[value] !== "" &&
              row[value] !== undefined
          );
        }),
        columns: columns,
      })
      .then((response) => {
        // console.log(response);
        setNotice("Import Completed");
      })
      .catch((err) => {
        setNotice(err);
      });
  }
  function saveHandler(values) {
    if (values) {
      Object.keys(values).forEach((i) => {
        const column = columns.find(
          (col) => col.name.toLowerCase() === i.toLowerCase()
        );

        if (column) {
          const table = column.table_name;

          if (table === "users" && column.editable) {
            const userValues = {
              [column.fieldName]: values[i],
            };

            axios
              .post(`${api_address}/admin/saveUser`, {
                ID: values.ID,
                userValues: userValues,
              })
              .then((response) => {
                setNotice("SAVED");
                // console.log(response.data, "response when saving");
              })
              .catch((err) => {
                setNotice(`ERROR SAVING: ${err} `);
                console.log(err);
              });
          } else {
            axios
              .post(`${api_address}/edit/updateData`, {
                ID: values[findIDField(i)],
                dataKey: column.fieldName,
                dataValue: values[i],
                table: table,
              })
              .then((response) => {
                setNotice("SAVED");
              })
              .catch((err) => {
                setNotice(`ERROR SAVING: ${err} `);

                console.log(err);
              });
          }
        }
      });
    }
  }

  function addHandler(values) {
    axios
      .post(`${api_address}/edit/addData`, {
        table: table,
        values: columns
          .filter(
            (i) =>
              i.display &&
              (Object.keys(i).includes("includeInUpdate")
                ? i.includeInUpdate
                : true)
          )
          .map((i) => i.name)
          .map((i) => values[i]),
        keys: columns
          .filter(
            (i) =>
              i.display &&
              (Object.keys(i).includes("includeInUpdate")
                ? i.includeInUpdate
                : true)
          )
          .map((i) => i.fieldName),
      })
      .then((response) => {
        // console.log(response.data, "data when added");
        getData();
        setNotice(`DATA ADDED ID: ${response.data.insertId}`);
      })
      .catch((err) => {
        setNotice(`ERROR ADDING DATA: ${err}`);
      });
  }
  function removeHandler(id) {
    // console.log("remove");
    axios
      .post(`${api_address}/edit/removeData`, {
        ID: id,
        table: table,
      })
      .then((response) => {
        // console.log(response.data);
      });
  }

  function exportTemplateHanlder() {
    ExportToExcel(
      {
        data: columns
          .filter((i) => {
            return i.name !== "ID";
          })
          .map((i) => {
            return { [i.name]: "" };
          }),
      },
      "Template"
    );
  }

  useEffect(() => {
    if (notice) {
      const timer = setTimeout(() => {
        setNotice();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [notice]);

  useEffect(() => {
    if (!searchValue) {
      getData();
    }
  }, []);

  return (
    <div>
      {search ? (
        <div style={{ position: "absolute", top: "15rem", left: "15rem" }}>
          Search
          <input
            style={{ border: "1px solid", marginLeft: "10px" }}
            // value={search}
            onChange={(e) => {
              //   setSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // getData();
              }
            }}
          ></input>
        </div>
      ) : (
        ""
      )}

      <EditableForm
        title={title}
        data={data}
        columns={columns}
        saveHandler={saveHandler}
        addHandler={allowAdd ? addHandler : ""}
        removeHandler={removeHandler}
        getData={getData}
        exportAvailable={exportAvailable}
        exportHandler={exportHandler}
        importBatch={importBatch}
        exportTemplateHanlder={exportTemplate ? exportTemplateHanlder : null}
        importUpdate={importUpdate}
        batchImportHandler={batchImportHandler}
        uploadImportHandler={uploadImportHandler}
        notice={notice}
      />
    </div>
  );
};

export default EditTemplate;
