import { useState, useEffect, React } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../../CSS/DealerLogin.css";
import { HiInformationCircle } from "react-icons/hi";
const ResetPassword = () => {
  const { resetID } = useParams();
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;
  const RESET_PASSWORD_URL = "/auth/resetPass";
  const navigate = useNavigate();
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [infoHover, setInfoHover] = useState(false);
  const [password, setPassword] = useState("");
  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState(false);

  useEffect(() => {
    if (errMsg) {
      const timer = setTimeout(() => {
        setErrMsg(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errMsg]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(password));
    setValidMatch(password === matchPwd && matchPwd !== "");
  }, [password, matchPwd]);

  function handleSubmit(e) {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = PWD_REGEX.test(password);
    if (validPwd && validMatch) {
      axios
        .post(
          `${api_address}${RESET_PASSWORD_URL}`,
          JSON.stringify({ password: password, token: resetID }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          setPassword("");
          setMatchPwd("");
          setSuccess(true);
        })
        .catch((err) => {
          if (!err?.response) {
            setErrMsg("No Server Response");
          } else {
            setErrMsg(err.response.data.error);
          }
        });
    }
  }

  return (
    <div className="request-pass-div">
      {!success ? (
        <div style={{ position: "relative" }}>
          <h1>Reset Password</h1>
          <div className="underline"></div>
          <form className="login-form">
            <div
              className={`login-input-holder pwd-reset ${pwdFocus && !validPwd
                ? "error-input"
                : validPwd
                  ? "valid-input"
                  : ""
                }`}
            >
              <input
                type="password"
                name="password"
                placeholder="Password"
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onFocus={() => {
                  setPwdFocus(true);
                }}
                required
              ></input>
            </div>
            <div
              className="error-msg-text"
              style={{
                display: "flex",
                alignItems: "center",
                alignSelf: "flex-start",
              }}
            >
              {pwdFocus && !validPwd ? "Invalid password." : ""}
              {pwdFocus && !validPwd ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#ACACAC",
                  }}
                  className="password-info-svg"
                >
                  <HiInformationCircle
                    onMouseEnter={() => {
                      setInfoHover(true);
                    }}
                    onMouseLeave={() => {
                      setInfoHover(false);
                    }}
                    style={{ marginLeft: "2px" }}
                  />
                  {infoHover ? (
                    <div className="password-info-popup">
                      - At least 8 characters  <br></br>- A mixture of both
                      uppercase and lowercase letters. <br></br>- A mixture of
                      letters and numbers. <br></br>- Inclusion of at least one
                      special character, e.g., ! @ # ?
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className={`login-input-holder pwd-reset ${matchFocus && !validMatch
                ? "error-input"
                : validMatch
                  ? "valid-input"
                  : ""
                }`}
            >
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                id="confirmPassword"
                value={matchPwd}
                onChange={(e) => setMatchPwd(e.target.value)}
                required
                onFocus={() => setMatchFocus(true)}
              ></input>
            </div>
            <div
              className="error-msg-text"
              style={{
                alignSelf: "flex-start",
              }}
            >
              {matchFocus && !validMatch ? "Passwords do not match." : ""}
            </div>
            {errMsg ? (
              <div
                className="error-msg-text"
                style={{ position: "absolute", bottom: "7.25rem" }}
              >
                {errMsg}
              </div>
            ) : (
              <></>
            )}

            <button
              className={`${validPwd && validMatch ? "" : "invalid"} submit pwd-reset dealer-button`}
              onClick={validPwd && validMatch ? handleSubmit : null}
            >
              Reset Password
            </button>
          </form>
        </div>
      ) : (
        <div className="confirmation-div">
          <div className="confirmation-msg" style={{ textAlign: "center", fontSize: "2rem" }}>
            Your password has been reset!
          </div>
          <button
            className="submit dealer-button pwd-reset"
            onClick={() => {
              navigate("/");
            }}
          >
            Go Home
          </button>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
