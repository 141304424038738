import "./CSS/App.css";
import { Route, Routes, useParams } from "react-router-dom";
import PersistLogin from "./Components/Login/PersistLogin";
import Home from "./Pages/Home";
import Admin from "./Pages/admin";
import Layout from "./Components/Layout";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Collection from "./Pages/Collection";
import Product from "./Pages/Product";
import Media from "./Pages/Media";
import RequestPasswordReset from "./Components/Login/requestPasswordReset";
import ResetPassword from "./Components/Login/resetPassword";
import ReactGA from "react-ga4";
import EditorTools from "./Pages/EditorTools";
import EditTable from "./Pages/EditTable";
import RequireAuth from "./Components/Login/RequireAuth";
import AdminPortal from "./Pages/AdminPortal";
import RequestLogin from "./Components/Login/RequestLogin";

import Unauthorized from "./Components/Login/Unauthorized";
import Search from "./Pages/Search";
import { useSelector } from "react-redux";
import { selectAuth } from "./ReduxSlice/auth";
import { useEffect } from "react";
// import markerSDK from "@marker.io/browser";
const TRACKING_ID = "G-P0ESNZ11ED"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.set({ username: localStorage.getItem("username") });
function App() {
  const auth = useSelector(selectAuth);

  //   async function loadWidget() {
  //     if (auth?.roles?.includes(3500)) {
  //       const widget = await markerSDK.loadWidget({
  //         project: "64778327517aa570924847af",
  //       });
  //     }
  //   }
  //   useEffect(() => {
  //     loadWidget();
  //   }, [auth?.roles]);

  return (
    <div className="App">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Montserrat"
      ></link>
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900"
        rel="stylesheet"
        type="text/css"
      ></link>
      <Routes>
        <Route element={<PersistLogin />}>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/collections/:collectionID" element={<Collection />} />
            <Route path="/products/:seriesID" element={<Product />} />
            <Route path="/media" element={<Media />} />
            <Route path="requestlogin" element={<RequestLogin />} />
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route path="/search" element={<Search />} />
            {/* <Route path="/admin" element={<Admin />} /> */}
            <Route path="reset-password">
              <Route path=":resetID" element={<ResetPassword />} />
              <Route path="" element={<RequestPasswordReset />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[1984]} />}>
              <Route path="/editTools" element={<EditorTools />} />
              <Route path="/editTable/:table" element={<EditTable />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[5150]} />}>
              <Route path="/adminPortal" element={<AdminPortal />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
