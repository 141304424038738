import { useRef, useState, useEffect, React } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setAuth } from "../../ReduxSlice/auth";
import { IoPersonSharp } from "react-icons/io5";
import { FaBriefcase } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaEnvelope } from "react-icons/fa";
import { RiLock2Fill } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";
import { RiErrorWarningFill } from "react-icons/ri";
import { HiInformationCircle } from "react-icons/hi";
import { setUserInfo } from "../../ReduxSlice/user";
const NAME_REGEX = /^[a-z ,.'-]+$/i;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = "/register";
const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;
const EMAIL_REGEX =
  // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /\S+@\S+\.\S+/;
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const SignupForm = () => {
  const { username, password } = useSelector((state) => state.user.value)
  const dispatch = useDispatch();
  const userRef = useRef();

  const [infoHover, setInfoHover] = useState(false);
  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");


  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [validCompany, setValidCompany] = useState(false);
  const [companyFocus, setCompanyFocus] = useState(false);

  const [validPhone, setValidPhone] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);

  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");

  const [existingDealer, setExistingDealer] = useState(false);
  const [existingDealerComment, setExistingDealerComment] = useState("");

  useEffect(() => {
    setValidName(NAME_REGEX.test(fullName));
  }, [fullName]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(password));
    setValidMatch(password === matchPwd);
  }, [password, matchPwd]);

  useEffect(() => {
    if (company !== "") {
      setValidCompany(true);
    } else {
      setValidCompany(false);
    }
  }, [company]);
  useEffect(() => {
    setValidPhone(PHONE_REGEX.test(phone));
  }, [phone]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(username));
  }, [username]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = EMAIL_REGEX.test(username);
    const v2 = PWD_REGEX.test(password);
    if (!v1 || !v2 || !validMatch) {
      if (!v1) {
        setEmailFocus(true);
      }
      if (!v2) {
        setPwdFocus(true);
      }
      if (!validMatch) {
        setMatchFocus(true);
      }
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(
        `${api_address}${REGISTER_URL}`,
        JSON.stringify({
          fullName,
          company,
          phone,
          username,
          password,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const rep = response?.data?.rep;
      const dealer = response?.data?.dealer;
      const phoneNum = response?.data?.phone;

      dispatch(setAuth({
        user: username,
        pwd: password,
        rep,
        dealer,
        phoneNum,
        roles,
        accessToken,
      }));
      dispatch(setUserInfo({ displayBoolean: false, displayLogin: true, username: "", password: "" }))
      setMatchPwd("");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
    }
    console.log(errMsg);
  };

  return (
    <div>
      <form className="login-form">
        {/* USERNAME FIELD */}
        <div
          className={`login-input-holder ${userFocus && !validName
            ? "error-input"
            : validName
              ? "valid-input"
              : ""
            }`}
        >
          <div className={`dealer-icon ${validName ? "valid-icon" : ""}`}>
            <IoPersonSharp />
          </div>
          <input
            type="text"
            name="fullname"
            placeholder="Full Name"
            id="fullname"
            value={fullName}
            onChange={(e) => {
              setFullName(e.target.value);
            }}
            onFocus={() => {
              setUserFocus(true);
            }}
            required
          ></input>
          {validName ? (
            <div className="dealer-msg valid">
              <FaCheckCircle />
            </div>
          ) : userFocus ? (
            <div className="dealer-msg error">
              <RiErrorWarningFill />
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* USERNAME ERROR */}
        <div className="error-msg-text">
          {userFocus && !validName ? "Required field is missing." : ""}
        </div>

        {/* COMPANY FIELD */}
        <div
          className={`login-input-holder ${companyFocus && !validCompany
            ? "error-input"
            : validCompany
              ? "valid-input"
              : ""
            }`}
        >
          <div className={`dealer-icon ${validCompany ? "valid-icon" : ""}`}>
            <FaBriefcase />
          </div>
          <input
            type="text"
            name="company"
            placeholder="Company Name"
            id="company"
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
            onFocus={() => {
              setCompanyFocus(true);
            }}
            required
          ></input>
          {validCompany ? (
            <div className="dealer-msg valid">
              <FaCheckCircle />
            </div>
          ) : companyFocus ? (
            <div className="dealer-msg error">
              <RiErrorWarningFill />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="error-msg-text">
          {companyFocus && !validCompany ? "Required field is missing." : ""}
        </div>

        {/* Phone Field */}
        <div
          className={`login-input-holder ${phoneFocus && !validPhone
            ? "error-input"
            : validPhone
              ? "valid-input"
              : ""
            }`}
        >
          <div className={`dealer-icon ${validPhone ? "valid-icon" : ""}`}>
            <BsFillTelephoneFill />
          </div>
          <input
            type="tel"
            name="tel"
            placeholder="Phone Number"
            id="tel"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            onFocus={() => setPhoneFocus(true)}
          ></input>
          {validPhone ? (
            <div className="dealer-msg valid">
              <FaCheckCircle />
            </div>
          ) : phoneFocus ? (
            <div className="dealer-msg error">
              <RiErrorWarningFill />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="error-msg-text">
          {phoneFocus && !validPhone ? "Required field is missing." : ""}
        </div>

        {/* Email Field */}
        <div
          className={`login-input-holder ${emailFocus && !validEmail
            ? "error-input"
            : validEmail
              ? "valid-input"
              : ""
            }`}
        >
          <div className={`dealer-icon ${validEmail ? "valid-icon" : ""}`}>
            <FaEnvelope />
          </div>
          <input
            type="email"
            name="email"
            placeholder="Email"
            id="email"
            ref={userRef}
            value={username}
            onChange={(e) => {
              dispatch(setUserInfo({ username: e.target.value }));
            }}
            required
            onFocus={() => {
              setEmailFocus(true);
            }}
          ></input>
          {validEmail ? (
            <div className="dealer-msg valid">
              <FaCheckCircle />
            </div>
          ) : emailFocus ? (
            <div className="dealer-msg error">
              <RiErrorWarningFill />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="error-msg-text">
          {emailFocus && !validEmail ? "Required field is missing." : ""}
        </div>
        {/* PASSWORD FIELD */}
        <div
          className={`login-input-holder ${pwdFocus && !validPwd
            ? "error-input"
            : validPwd
              ? "valid-input"
              : ""
            }`}
        >
          <div className={`dealer-icon ${validPwd ? "valid-icon" : ""}`}>
            <RiLock2Fill />
          </div>
          <input
            type="password"
            name="password"
            placeholder="Password"
            id="password"
            value={password}
            onChange={(e) => {
              dispatch(setUserInfo({ password: e.target.value }));
            }}
            required
            onFocus={() => setPwdFocus(true)}
          ></input>
          {validPwd ? (
            <div className="dealer-msg valid">
              <FaCheckCircle />
            </div>
          ) : pwdFocus ? (
            <div className="dealer-msg error">
              <RiErrorWarningFill />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className="error-msg-text"
          style={{ display: "flex", alignItems: "center" }}
        >
          {pwdFocus && !validPwd ? "See Password Requirements" : ""}
          {pwdFocus && !validPwd ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#ACACAC",
                marginLeft: "5px",
              }}
              className="password-info-svg"
            >
              <HiInformationCircle
                onMouseEnter={() => {
                  setInfoHover(true);
                }}
                onMouseLeave={() => {
                  setInfoHover(false);
                }}
              />
              {infoHover ? (
                <div className="password-info-popup">
                  - At least 8 characters  <br></br>- A mixture of both
                  uppercase and lowercase letters. <br></br>- A mixture of
                  letters and numbers. <br></br>- Inclusion of at least one
                  special character, e.g., ! @ # ?
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        <div
          className={`login-input-holder ${matchFocus && !validMatch
            ? "error-input"
            : validMatch && validPwd
              ? "valid-input"
              : ""
            }`}
        >
          <div
            className={`dealer-icon ${validMatch && validPwd ? "valid-icon" : ""
              }`}
          >
            <RiLock2Fill />
          </div>
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            id="confirmPassword"
            value={matchPwd}
            onChange={(e) => setMatchPwd(e.target.value)}
            required
            onFocus={() => setMatchFocus(true)}
          ></input>
          {validMatch && validPwd ? (
            <div className="dealer-msg valid">
              <FaCheckCircle />
            </div>
          ) : matchFocus && !validMatch ? (
            <div className="dealer-msg error">
              <RiErrorWarningFill />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="error-msg-text">
          {matchFocus && !validMatch ? "Passwords do not match." : ""}
        </div>
        <button className="submit" onClick={handleSubmit}>
          SIGN UP
        </button>
        <div
          className="error-msg-text"
          style={{ textAlign: "center", marginBottom: "0" }}
        >
          {errMsg ? `${errMsg}` : ""}
        </div>
      </form>
      <p className="need-account">Already Have an Account?</p>
      <h3 className="sign-up-link" onClick={() => dispatch(setUserInfo({ displayLogin: true }))}>
        LOGIN
      </h3>
    </div>
  );
};

export default SignupForm;
