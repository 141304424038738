import React, { useState, useEffect } from "react";
import CategoryTile from "./CategoryTile";
import Axios from "axios";
const CategoriesSections = () => {
  const [categories, setCategories] = useState();
  const [pointer, setPointer] = useState(0);
  const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;
  function getData() {
    Axios.get(`${api_address}/products/getCategories`, {
      // collectionID: collectionID,
    }).then((response) => {
      //   console.log(response.data);
      setCategories(response.data);
    });
  }
  function addPointer() {
    if (pointer === categories.length - 1) {
      setPointer(0);
    } else {
      setPointer(pointer + 1);
    }
  }
  function removePointer() {
    if (pointer === 0) {
      setPointer(categories.length - 1);
    } else {
      setPointer(pointer - 1);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ background: "rgba(var(--color-foreground),.04)" }}>
      <div className="categories-section">
        {categories?.map((i, index) => {
          return (
            <CategoryTile
              key={index}
              category={i}
              pointer={pointer}
              index={index}
            />
          );
        })}
      </div>
      <div className="slider-navigation">
        <div onClick={removePointer}>{"<"}</div>
        {pointer + 1}/{categories?.length}
        <div onClick={addPointer}>{">"}</div>
      </div>
    </div>
  );
};

export default CategoriesSections;
