import React, { useState, useEffect } from "react";
import Axios from "axios";
import { createSearchParams, useNavigate } from "react-router-dom";
import CategoryBar from "../Components/Media/CategoryBar";
import ImageTransformations from "../Components/Media/ImageTransformations";
import MediaButtons from "../Components/Media/MediaButtons";
import MediaTiles from "../Components/Media/MediaTiles";
import saveAs from "file-saver";
import * as XLSX from "xlsx/xlsx.mjs";
import "../CSS/Media.css";

import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
import DownloadExcel from "../Components/Media/DownloadExcel";
const Media = () => {
  const gaEventTracker = useAnalyticsEventTracker("Media Page");
  useEffect(() => {
    document.title = `Media - Hampden Furnishings`;
    gaEventTracker("Media Center - Page Visited");
  }, []);
  const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;
  const [catLevels, setCatLevels] = useState([]);
  const [fullMediaList, setFullMediaList] = useState();
  const [filteredMediaList, setFilteredMediaList] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedImages, setSelectedImages] = useState();
  const [categories, setCategories] = useState();
  const [clearList, setClearList] = useState(false);
  const [imageEffects, setImageEffects] = useState({});
  const [applyEffects, setApplyEffects] = useState(false);
  const navigate = useNavigate();

  function getData() {
    Axios.get(`${api_address}/products/getImageLinks`).then((response) => {
      // console.log(response.data, 30);
      setFullMediaList(response.data.images);
      setFilteredMediaList(response.data.images);
    });
  }

  function getCategories() {
    Axios.get(`${api_address}/products/getProductTree`).then((response) => {
      // console.log(response.data, 37);
      setCategories(response.data);
    });
  }

  function addToSelected(imageLink) {
    setSelectedImages([...(selectedImages ? selectedImages : []), imageLink]);
  }

  function removeFromSelected(imageLink) {
    setSelectedImages(selectedImages?.filter((i) => i !== imageLink));
  }

  function filterMediaList() {
    if (catLevels.length) {
      var holder = {};
      Object.keys(fullMediaList).forEach((i) => {
        if (fullMediaList[i]?.tags?.includes(catLevels[catLevels.length - 1])) {
          holder[i] = fullMediaList[i];
        }
        setFilteredMediaList(holder);
      });
    } else {
      setFilteredMediaList(fullMediaList, "media list");
    }
  }

  useEffect(() => {
    filterMediaList();
    setSelectAll(false);
    // console.log(selectAll);
    setSelectedImages();
    gaEventTracker(
      `Category changed - ${
        catLevels
          ? Object.keys(catLevels).map((i) => {
              return `${catLevels[i]}`;
            })
          : ""
      }`
    );
  }, [catLevels]);

  useEffect(() => {
    getData();
    getCategories();
  }, []);

  return (
    <div className="media-page">
      <CategoryBar
        categories={categories}
        catLevels={catLevels}
        setCatLevels={setCatLevels}
        setImageEffects={setImageEffects}
        imageEffects={imageEffects}
        setApplyEffects={setApplyEffects}
      />

      <div className="flexbox2">
        <MediaButtons
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          selectedList={selectedImages}
          setSelectedList={setSelectedImages}
          mediaList={filteredMediaList}
          setClearList={setClearList}
          gaEventTracker={gaEventTracker}
          imageEffects={imageEffects}
          applyEffects={applyEffects}
          setApplyEffects={setApplyEffects}
        />
        <DownloadExcel
          mediaList={fullMediaList}
          imageEffects={imageEffects}
          applyEffects={applyEffects}
        />

        <MediaTiles
          list={filteredMediaList}
          selectAll={selectAll}
          addToSelected={addToSelected}
          removeFromSelected={removeFromSelected}
          clearList={clearList}
          selectedImages={selectedImages}
          gaEventTracker={gaEventTracker}
          imageEffects={imageEffects}
          applyEffects={applyEffects}
        />
      </div>
    </div>
  );
};

export default Media;
