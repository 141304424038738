import React, { useEffect, useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { MdCircle } from "react-icons/md";
import { GiCircle } from "react-icons/gi";
import "../../CSS/Home.css";
import axios from "axios";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;
const Slider = () => {
  const [banners, setBanners] = useState();
  // const banners = [
  //   "https://res.cloudinary.com/da3rom333/image/upload/v1678722065/OEF/Banners/Banner_Home-01-01_2000x_e5cdad.webp",
  //   "https://res.cloudinary.com/da3rom333/image/upload/v1678722352/OEF/Banners/Banner_home-01-01_d2e0073d-2c35-4094-8f8c-7c653fb5a7c9_2000x_clwyi0.webp",
  // ];

  async function getBanners() {
    await axios
      .post(`${api_address}/content/getBanners`, {
        page: "home",
      })
      .then((response) => {
        setBanners(response.data.map((i) => i.image_link));
      });
  }

  useEffect(() => {
    getBanners();
    // console.log(banners, 28);
  }, []);
  function nextImage() {
    if (pointer === banners.length - 1) {
      setPointer(0);
    } else {
      setPointer(pointer + 1);
    }
  }
  function prevImage() {
    if (pointer === 0) {
      setPointer(banners.length - 1);
    } else {
      setPointer(pointer - 1);
    }
  }
  const [pointer, setPointer] = useState(0);
  return (
    <div>
      <div className="home-slider">
        {banners && (
          <img
            src={`https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${banners[pointer]}`}
          ></img>
        )}
      </div>
      <div className="slider-navigate">
        <div className="slider-navigate-button" onClick={prevImage}>
          <BsChevronLeft />
        </div>
        {banners?.map((i, index) => {
          return (
            <div
              onClick={() => {
                setPointer(index);
              }}
              className={`${
                index === pointer ? "active-pointer" : ""
              } slider-pointer`}
              key={index}
            >
              {index === pointer ? <MdCircle /> : <GiCircle />}
            </div>
          );
        })}
        <div className="slider-navigate-button" onClick={nextImage}>
          <BsChevronRight />
        </div>
      </div>
    </div>
  );
};
export default Slider;
