import React, { useState } from "react";

const ImageWithHover = ({ src }) => {
  const [hover, setHover] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <div>
        {hover ? (
          <img
            src={
              src?.startsWith("v")
                ? `https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${src?.replace(
                    ".pdf",
                    ".jpg"
                  )}`
                : src?.replace(".pdf", ".jpg")
            }
            style={{
              height: "250px",
              width: "250px",
              objectFit: "contain",
              position: "absolute",
              top: "-16rem",
              left: "-7rem",
              boxShadow: "1px 5px 5px grey",
              background: "white",
            }}
          ></img>
        ) : (
          ""
        )}
      </div>
      <img
        onClick={() => {
          window.open(
            src?.startsWith("v")
              ? `https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${src}`
              : src
          );
        }}
        src={
          src?.startsWith("v")
            ? `https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${src?.replace(
                ".pdf",
                ".jpg"
              )}`
            : src?.replace(".pdf", ".jpg")
        }
        style={{
          height: "45px",
          width: "45px",
          objectFit: "contain",
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      ></img>
    </div>
  );
};

export default ImageWithHover;
