import axios from "axios";
import React, { useEffect, useState } from "react";
import "../CSS/EditorTools.css";
import EditTemplate from "../Components/EditorTools/EditTemplate";
import { useNavigate } from "react-router-dom";
import useExportToExcel from "../Components/Shared/useExportToExcel";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;
const EditorTools = () => {
  const navigate = useNavigate();
  const ExportToExcel = useExportToExcel();
  const [categories, setCategories] = useState();
  async function getEditCategories() {
    await axios
      .post(`${api_address}/edit/getEditCategories`, { page: "edit" })
      .then((response) => {
        setCategories(response.data);
        // console.log(response.data);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getEditCategories();
  }, []);

  async function getSkuList() {
    axios
      .get(`${api_address}/edit/getSkuList`)
      .then((res) => {
        ExportToExcel({ data: res.data }, "skulist");
      })
      .catch((err) => [console.log(err)]);
  }

  async function handleClick(table) {
    if (table?.route_name) {
      if (table.route_name === "getSkuList") {
        await getSkuList();
      } else {
        navigate(`/${table.route_name}`);
      }
    } else {
      navigate(`/EditTable/${table.table_name}`);
    }
  }

  return (
    <>
      <h1>EDITOR TOOLS</h1>
      <div className="inner-padding edit-buttons-div">
        {categories?.map((i, index) => {
          return (
            <button
              onClick={() => handleClick(i)}
              className="edit-button"
              key={index}
            >
              {(!i.route_name ? "Edit " : "") +
                `${i?.title?.replace(/_/g, " ")}`}
            </button>
          );
        })}
      </div>
    </>
  );
};

export default EditorTools;
