import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  AiOutlineCloseCircle,
  AiOutlineDownCircle,
  AiOutlineUpCircle,
} from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { SlArrowDown } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { HiInformationCircle } from "react-icons/hi";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const ImageTransformations = ({
  setImageEffects,
  imageEffects,
  setApplyEffects,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [transformations, setTransformations] = useState([]);
  const [openSuboptions, setOpenSuboptions] = useState(false);
  const [infoHover, setInfoHover] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 2000);
    return () => clearTimeout(timer);
  }, [message]);

  function handleTitleClick(type) {
    if (openOptions === type) {
      setOpenOptions(false);
    } else {
      setOpenOptions(type);
    }
  }

  async function getTransformations() {
    axios.get(`${api_address}/content/getTransformations`).then((response) => {
      setTransformations(response.data);
    });
  }

  useEffect(() => {
    getTransformations();
  }, []);

  function handleApply() {
    setApplyEffects(true);
    setOpenOptions(false);
    setMessage("Applied!");
  }

  const handleInputChangeAdd = (event) => {
    const { name, value } = event.target;
    const transformName = transformations.find((i) => i.text === name);
    const isNum = !isNaN(parseFloat(value)) && isFinite(value);
    if (!isNum && transformName.options === "px" && value !== "") {
      // setErrorNotification(true);
    } else if (imageEffects) {
      setImageEffects((values) => ({
        ...values,
        [transformName.code]: value,
      }));
    } else {
      setImageEffects({
        [transformName.code]: value,
      });
    }
  };

  return (
    <>
      <h2 style={{ marginTop: "2.5rem", fontSize: "24px", textAlign: "left" }}>
        Transformations
      </h2>
      <div className="category-list-div">
        <div style={{ display: "flex", cursor: "pointer" }}>
          <h3
            onClick={() => {
              handleTitleClick("transformations");
            }}
          >
            Select Transformations
          </h3>
          <h3>
            {" "}
            <HiInformationCircle
              style={{
                cursor: "pointer",
                verticalAlign: "text-bottom",
                marginLeft: "10px",
              }}
              onClick={() => {
                setInfoHover(!infoHover);
              }}
            />
          </h3>

          {infoHover ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "7rem",
                marginLeft: "85px",
                padding: "10px",
                width: "14rem",
                cursor: "initial",
              }}
              className="password-info-popup"
              onMouseLeave={() => setInfoHover(false)}
            >
              <p
                style={{
                  textTransform: "none",
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "18px",
                  margin: "10px 0px",
                }}
              >
                {" "}
                Click here to learn more about how to transform your images!
              </p>
              <div
                style={{ marginTop: "0px" }}
                className="media-download-buttons"
              >
                <button
                  className="option"
                  onClick={() =>
                    window.open(
                      "https://res.cloudinary.com/da3rom333/image/upload/v1702656806/Misc%20Assets/IMAGE_TRANSFORMATIONS_ry7weo.pdf",
                      "_blank"
                    )
                  }
                  style={{
                    height: "2rem",
                    width: "10rem",
                    padding: "5px",
                    marginBottom: "10px",
                  }}
                >
                  Learn More
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div style={{ padding: "0px", height: "18.4rem" }}>
          <div
            style={{ padding: "8px", height: "17rem" }}
            className="category-list"
          >
            {transformations.map((i, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    marginTop: "12px",
                    height: "30px",
                    justifyContent: "space-between",
                  }}
                  key={index}
                >
                  <h3 style={{ fontSize: "12px", cursor: "default" }}>
                    {i.text.replace(/_/g, " ")}
                  </h3>
                  {i.options.includes(";") ? (
                    <div
                      className="select-cat"
                      onClick={() => {
                        if (openSuboptions === i.text) {
                          setOpenSuboptions(false);
                        } else {
                          setOpenSuboptions(i.text);
                        }
                      }}
                      style={{
                        width: "10.6rem",
                        height: "30px",
                        fontSize: "12px",
                        cursor: "pointer",
                        boxShadow: "none",
                        margin: "0px",
                        padding: "0px 10px",
                        display: "flex",
                        justifyContent: "space-between",

                        borderColor:
                          openSuboptions === i.text ? "black" : "#BBBBBA",
                        color: openSuboptions === i.text ? "black" : "#707070",
                      }}
                    >
                      {imageEffects[i.code] ? imageEffects[i.code] : "Select"}
                      <div
                        onClick={() => {
                          if (openSuboptions === i.text) {
                            setOpenSuboptions(false);
                          } else {
                            setOpenSuboptions(i.text);
                          }
                        }}
                      >
                        <SlArrowDown
                          style={{
                            width: "12px",
                            transform:
                              openSuboptions === i.text ? "rotate(180deg)" : "",
                            transition: ".3s",
                          }}
                        />
                      </div>{" "}
                    </div>
                  ) : (
                    <input
                      style={{
                        fontSize: "11px",
                        width: "10.6rem",
                        textTransform: "uppercase",
                        border: "1px solid #BBBBBA",
                        borderRadius: "8px",
                        padding: "2px 10px",
                      }}
                      onChange={handleInputChangeAdd}
                      value={imageEffects[i.code] || ""}
                      name={i.text}
                      placeholder={i.options}
                    ></input>
                  )}
                  {openSuboptions === i.text && (
                    <div
                      className="category-list-div"
                      style={{
                        position: "absolute",
                        width: "12rem",
                        marginLeft: "5.85rem",
                        marginTop: "2.2rem",
                      }}
                    >
                      <div
                        className="category-list"
                        style={{ height: "100% " }}
                      >
                        {i.options.split(";").map((k, index) => {
                          return (
                            <div key={index}>
                              <h3
                                style={{ fontSize: "12px" }}
                                onClick={(e) => {
                                  if (imageEffects) {
                                    if (imageEffects[i.code] === k) {
                                      setImageEffects((values) => ({
                                        ...values,
                                        [i.code]: "",
                                      }));
                                    } else {
                                      setImageEffects((values) => ({
                                        ...values,
                                        [i.code]: k,
                                      }));
                                    }
                                  } else {
                                    setImageEffects({
                                      [i.code]: k,
                                    });
                                  }
                                  setOpenSuboptions("");
                                }}
                              >
                                <div
                                  onClick={() => {
                                    if (imageEffects) {
                                      if (imageEffects[i.code] === k) {
                                        setImageEffects((values) => ({
                                          ...values,
                                          [i.code]: "",
                                        }));
                                      } else {
                                        setImageEffects((values) => ({
                                          ...values,
                                          [i.code]: k,
                                        }));
                                      }
                                    } else {
                                      setImageEffects({
                                        [i.code]: k,
                                      });
                                    }
                                    setOpenSuboptions("");
                                  }}
                                  className={"selection-box-sku"}
                                  style={{
                                    marginRight: ".5rem",
                                    border: "1px solid",
                                    borderColor:
                                      imageEffects[i.code] === k
                                        ? "var(--lightgreen)"
                                        : "#808080",
                                  }}
                                >
                                  {imageEffects[i.code] === k ? (
                                    <FaCheck />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {k}
                              </h3>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

            <div
              style={{ marginTop: "25px", justifyContent: "space-around" }}
              className="media-download-buttons"
            >
              <div style={{ position: "relative" }}>
                <button
                  className="option"
                  style={{ width: "135px", height: "35px" }}
                  onClick={handleApply}
                >
                  Apply
                </button>
                {message &&
                Object.values(imageEffects).some((i) => i !== "") ? (
                  <div
                    className="option"
                    style={{
                      color: "white",
                      background: "black",
                      position: "absolute",
                      bottom: "-10px",
                      left: "-10px",
                      width: "88px",
                      height: "19px",
                    }}
                  >
                    {message}
                  </div>
                ) : (
                  " "
                )}
              </div>
              <button
                className="option"
                style={{ width: "135px", height: "35px" }}
                onClick={() => {
                  setApplyEffects(false);
                  setImageEffects({});
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageTransformations;
