import React from "react";
import useExportToExcel from "../Shared/useExportToExcel";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";

const DownloadExcel = ({ mediaList, imageEffects, applyEffects }) => {
  const ExportToExcel = useExportToExcel();
  const gaEventTracker = useAnalyticsEventTracker("Media Page");
  const createFilters = async () => {
    if (
      Object.keys(imageEffects)
        .filter((i) => i !== "type")
        .filter((i) => imageEffects[i] !== "").length > 0
    ) {
      var string = "";

      string +=
        "/" +
        Object.keys(imageEffects)
          .filter((i) => i !== "type")
          .filter((i) => imageEffects[i] !== "")
          .map((key) => {
            return `${key}_${imageEffects[key]}`;
          })
          .join(",");
      if (
        !Object.keys(imageEffects).includes("q") ||
        imageEffects["q"] === ""
      ) {
        string = string + ",q_auto";
      }
      return string;
    }
    return "";
  };

  async function mapMediaList() {
    var mappedMediaList = [];
    let filterString = "";

    if (applyEffects) {
      filterString = await createFilters();
    }
    for (var i in mediaList) {
      var holderObject = { SKU: i };
      //   console.log(mediaList[i]);
      for (var j = 0; j < mediaList[i].imageLinks.length; j++) {
        let newImage;
        if (
          Object.keys(imageEffects).includes("type") &&
          imageEffects["type"] !== ""
        ) {
          newImage =
            mediaList[i].imageLinks[j].substring(
              0,
              mediaList[i].imageLinks[j].lastIndexOf(".") + 1
            ) + imageEffects["type"];
        } else {
          newImage = mediaList[i].imageLinks[j];
        }
        // console.log(mediaList[i].imageLinks[j]);
        holderObject[
          `Image${j + 1}`
        ] = `https://res.cloudinary.com/da3rom333/image/upload${filterString}/${encodeURI(
          newImage
        )}`;
      }

      for (var j = 0; j < mediaList[i]?.pdfLinks?.length; j++) {
        // console.log(mediaList[i].imageLinks[j]);
        holderObject[
          `PDF${j + 1}`
        ] = `https://res.cloudinary.com/da3rom333/image/upload/${encodeURI(
          mediaList[i].pdfLinks[j]
        )}`;
      }
      for (var j = 0; j < mediaList[i]?.videoLinks?.length; j++) {
        // console.log(mediaList[i].imageLinks[j]);
        holderObject[`Video${j + 1}`] = `${encodeURI(
          mediaList[i].videoLinks[j][Object.keys(mediaList[i].videoLinks[j])[0]]
        )}`;
      }
      mappedMediaList.push(holderObject);
    }
    return mappedMediaList;
  }

  async function downloadLinks() {
    gaEventTracker(`List of all image links downloaded`);
    const mappedMediaList = await mapMediaList();
    ExportToExcel({ data: mappedMediaList }, "media list");
  }
  return (
    <div className="media-download-buttons">
      <button
        onClick={() => {
          downloadLinks();
        }}
        className="option"
        style={{ width: "100%" }}
      >
        Download List of All Media Links
      </button>
    </div>
  );
};

export default DownloadExcel;
