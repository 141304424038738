// import "./UploadFile.css";
import excelToJson from "./ExcelToJson";
import { useRef } from "react";
function UploadFile({ text, setData }) {
  //   const [success, setSuccess] = useState(false);
  const convertExcelToObject = (file) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = new Uint8Array(event.target.result);
      //   console.log(data);
      let result = excelToJson({ source: data });
      //   console.log(result);
      setData(result);
    };
    reader.readAsArrayBuffer(file);
  };
  //   const dropHandler = (event) => {
  //     event.preventDefault();
  //     const file = event.dataTransfer.files[0];
  //     convertExcelToObject(file);
  //   };

  const uploadFileHandler = (event) => {
    const file = event.target.files[0];
    // console.log(file);
    convertExcelToObject(file);
  };

  //   const dragOverHandler = (event) => {
  //     event.preventDefault();
  //   };
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  return (
    <div className="uploadFile">
      {/* <label></label> */}
      <div>
        <label
          // onDrop={dropHandler} onDragOver={dragOverHandler}e
          htmlFor="file"
        >
          <button className="editable-form-button" onClick={handleClick}>
            {text}
          </button>
          <div>
            <input
              onChange={uploadFileHandler}
              ref={hiddenFileInput}
              id="file"
              type="file"
              accept=".xlsx, .xls, .csv"
              style={{ display: "none" }}
            />
            {/* <div>or drop excel files here</div> */}
          </div>
        </label>
      </div>
    </div>
  );
}
export default UploadFile;
