import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { setUserInfo } from "../../ReduxSlice/user";
import { useSelector, useDispatch } from "react-redux";
import "../../CSS/Unauthorized.css";
import { IoLockClosedSharp } from "react-icons/io5";
const banner =
  "https://res.cloudinary.com/da3rom333/image/upload/v1701719174/Hampden%20Assets/banners/Unauthorized_Page_text_kwiq6u.jpg";
const RequestLogin = () => {
  const { displayBoolean } = useSelector((state) => state.user.value);
  const auth = useSelector((state) => state.auth.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const goBack = () => navigate(-1);
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  function open() {
    dispatch(
      setUserInfo({
        displayBoolean: true,
        username: "",
        password: "",
        displayLogin: true,
      })
    );
  }
  useEffect(() => {
    dispatch(
      setUserInfo({
        displayBoolean: true,
        username: "",
        password: "",
        displayLogin: true,
      })
    );
  }, []);

  useEffect(() => {
    if (!displayBoolean && auth?.rep) {
      navigate(from);
    }
  }, [displayBoolean]);

  return (
    // change this to new Unauthorized component
    <div className="unauthorized">
      <img style={{ width: "100%" }} src={banner}></img>
      <div
        style={{
          top: "auto",
          left: "auto",
          marginTop: "-19rem",
          width: "100%",
        }}
        className="buttons"
      >
        {/* <IoLockClosedSharp style={{ width: "70px", height: "70px" }} /> */}
        {!auth?.rep ? (
          <button onClick={open}>Login</button>
        ) : (
          <button>Request Access</button>
        )}
        <a style={{ fontSize: "17px" }} href="/">
          Go Home
        </a>
      </div>

      {/* <div style={{ height: "55rem" }}></div> */}
    </div>
  );
};

export default RequestLogin;
