import React, { useState, useEffect } from "react";
import { HiArrowLongRight } from "react-icons/hi2";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [succes, setSuccess] = useState(false);
  const email_regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess(false);
      setErrMsg("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [succes, errMsg]);
  function submitForm(e) {
    e.preventDefault();
    // console.log("here");
    if (email.match(email_regex)) {
      // submite here
      setEmail("");
      setSuccess(true);
      setErrMsg();
    } else {
      setErrMsg("Invalid email address.");
    }
  }
  return (
    <div className="footer">
      {/* <div className="footer-subscribe" style={{ position: "relative" }}>
        <h2>Subscribe to receive the latest news!</h2>{" "}
        <div style={{ display: "flex" }}>
          <input
            placeholder="Email"
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>

          <HiArrowLongRight onClick={submitForm} />
        </div>
        <div
          className="errmsg"
          style={{ position: "absolute", bottom: "1rem" }}
        >
          {succes ? "Thank you for your submission." : errMsg ? errMsg : ""}
        </div>
      </div> */}
      <div className="copy">
        © 2024, <a href="/">Hampden Furnishings</a>
      </div>
    </div>
  );
};

export default Footer;
