import React, { useEffect, useState, useRef } from "react";
import "../../CSS/DealerLogin.css";
import { useDispatch } from "react-redux";
import { IoPersonSharp } from "react-icons/io5";
import { FaBriefcase } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaEnvelope } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { RiErrorWarningFill } from "react-icons/ri";
import { BiChevronDown } from "react-icons/bi";
import useAxiosJWT from "../../Hooks/useAxiosJWT";
import { setUserInfo } from "../../ReduxSlice/user";
const NAME_REGEX = /^[a-z ,.'-]+$/i;
const REGISTER_URL = "/register";
const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// /\S+@\S+\.\S+/;
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const HelpForm = ({
  setShowRegistered,
  setShowHelp,
  setForm,
}) => {
  const dispatch = useDispatch();
  const userRef = useRef();
  const errRef = useRef();

  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [validCompany, setValidCompany] = useState(false);
  const [companyFocus, setCompanyFocus] = useState(false);

  const [validPhone, setValidPhone] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);

  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState();

  const [selection, setSelection] = useState("Please Select");
  const [showSelect, setShowSelect] = useState(false);
  const [validSelection, setValidSelection] = useState(false);
  const [selectionFocus, setSelectionFocus] = useState(false);

  const [validComments, setValidComments] = useState(false);
  const [commentFocus, setCommentFocus] = useState(false);

  useEffect(() => {
    if (comments.trim().length > 0) {
      setValidComments(true);
    }
  }, [comments]);

  useEffect(() => {
    if (selection !== "Please Select") {
      setValidSelection(true);
    }
  }, [selectionFocus, selection]);

  useEffect(() => {
    setValidName(NAME_REGEX.test(fullName));
  }, [fullName]);

  useEffect(() => {
    if (company !== "") {
      setValidCompany(true);
    } else {
      setValidCompany(false);
    }
  }, [company]);
  useEffect(() => {
    setValidPhone(PHONE_REGEX.test(phone));
  }, [phone]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    if (comments !== "") {
      setValidComments(true);
    } else {
      setValidComments(false);
    }
  }, [comments]);


  const axiosJWT = useAxiosJWT();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validEmail || !validSelection || !validComments) {
      if (!validEmail) {
        setEmailFocus(true);
      }
      if (!validSelection) {
        setSelectionFocus(true);
      }
      if (!validComments) {
        setCommentFocus(true);
      }
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axiosJWT.post(`register/helpform`, {
        fullName: fullName,
        company: company,
        phone: phone,
        email: email,
        selection: selection,
        comments: comments,
      });

      dispatch(setUserInfo({ displayBoolean: true }));
      setShowHelp(false);
      setForm("showHelp");
      setShowRegistered(true);

      setEmail("");
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else {
        setErrMsg("Registration Failed");
      }
    }
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
    >
      <div className="help-form-outer-div">
        <form className="login-form help-form">
          {/* USERNAME FIELD */}
          <div className="help-form-right">
            <div
              className={`login-input-holder ${userFocus && !validName
                ? "error-input"
                : validName
                  ? "valid-input"
                  : ""
                }`}
            >
              <div className={`dealer-icon ${validName ? "valid-icon" : ""}`}>
                <IoPersonSharp />
              </div>
              <input
                type="text"
                name="fullname"
                placeholder="Full Name"
                id="fullname"
                value={fullName}
                onChange={(e) => {
                  setFullName(e.target.value);
                }}
                onFocus={() => {
                  setUserFocus(true);
                }}
                required
              ></input>
              {validName ? (
                <div className="dealer-msg valid">
                  <FaCheckCircle />
                </div>
              ) : userFocus ? (
                <div className="dealer-msg error">
                  <RiErrorWarningFill />
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* USERNAME ERROR */}
            <div className="error-msg-text">
              {userFocus && !validName ? "Required field is missing." : ""}
            </div>

            {/* COMPANY FIELD */}
            <div
              className={`login-input-holder ${companyFocus && !validCompany
                ? "error-input"
                : validCompany
                  ? "valid-input"
                  : ""
                }`}
            >
              <div className={`dealer-icon ${validCompany ? "valid-icon" : ""}`}>
                <FaBriefcase />
              </div>
              <input
                type="text"
                name="company"
                placeholder="Company Name"
                id="company"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
                onFocus={() => {
                  setCompanyFocus(true);
                }}
                required
              ></input>
              {validCompany ? (
                <div className="dealer-msg valid">
                  <FaCheckCircle />
                </div>
              ) : companyFocus ? (
                <div className="dealer-msg error">
                  <RiErrorWarningFill />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="error-msg-text">
              {companyFocus && !validCompany ? "Required field is missing." : ""}
            </div>

            {/* Phone Field */}
            <div
              className={`login-input-holder ${phoneFocus && !validPhone
                ? "error-input"
                : validPhone
                  ? "valid-input"
                  : ""
                }`}
            >
              <div className={`dealer-icon ${validPhone ? "valid-icon" : ""}`}>
                <BsFillTelephoneFill />
              </div>
              <input
                type="tel"
                name="tel"
                placeholder="Phone Number"
                id="tel"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                onFocus={() => setPhoneFocus(true)}
              ></input>
              {validPhone ? (
                <div className="dealer-msg valid">
                  <FaCheckCircle />
                </div>
              ) : phoneFocus ? (
                <div className="dealer-msg error">
                  <RiErrorWarningFill />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="error-msg-text">
              {phoneFocus && !validPhone ? "Required field is missing." : ""}
            </div>

            {/* Email Field */}
            <div
              className={`login-input-holder ${emailFocus && !validEmail
                ? "error-input"
                : validEmail
                  ? "valid-input"
                  : ""
                }`}
            >
              <div className={`dealer-icon ${validEmail ? "valid-icon" : ""}`}>
                <FaEnvelope />
              </div>
              <input
                type="email"
                name="email"
                placeholder="Email"
                id="email"
                ref={userRef}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
                onFocus={() => {
                  setEmailFocus(true);
                }}
              ></input>
              {validEmail ? (
                <div className="dealer-msg valid">
                  <FaCheckCircle />
                </div>
              ) : emailFocus ? (
                <div className="dealer-msg error">
                  <RiErrorWarningFill />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="error-msg-text">
              {emailFocus && !validEmail ? "Required field is missing." : ""}
            </div>
          </div>
          <div className="help-form-left">
            <div
              style={{
                borderColor:
                  selectionFocus && !validSelection
                    ? "#c80e1a"
                    : validSelection
                      ? "#01435b"
                      : "#acacac",
              }}
              onClick={() => {
                setShowSelect(!showSelect);
                setSelectionFocus(true);
                console.log(selectionFocus, 382);
              }}
              className={`login-input-holder selection ${selection !== "Please Select" ? "valid-input" : ""
                }`}
            >
              <div
                style={{
                  fontFamily: selection !== "Please Select" ? "Helvetica" : " ",
                  color: selection !== "Please Select" ? "#acacac" : " ",
                }}
                className="select-down"
              >
                {selection}
              </div>
              <BiChevronDown
                onClick={() => {
                  setShowSelect(!showSelect);
                }}
                className="laminate-select-down laminates-svg"
              />
            </div>
            {showSelect ? (
              <div className="select-options-dealer">
                <div
                  className={`select-option ${selection === "Request Quote" ? "select-option-selected" : ""
                    }`}
                  onClick={() => {
                    setSelection("Request Quote");
                    setShowSelect(false);
                  }}
                >
                  Request Quote
                </div>
                <div
                  className={`select-option ${selection === "Become a Dealer"
                    ? "select-option-selected"
                    : ""
                    }`}
                  onClick={() => {
                    setSelection("Become a Dealer");
                    setShowSelect(false);
                  }}
                >
                  Become a Dealer
                </div>
                <div
                  className={`select-option ${selection === "Other" ? "select-option-selected" : ""
                    }`}
                  onClick={() => {
                    setSelection("Other");
                    setShowSelect(false);
                  }}
                >
                  Other
                </div>
              </div>
            ) : (
              ""
            )}
            {selection && selection !== "Please Select" && (
              <div
                style={{
                  top: "11.25rem",
                  left: "43rem",
                  position: "absolute",
                  background: "black",
                }}
                className="dealer-msg valid"
              >
                <FaCheckCircle />
              </div>
            )}
            <div className="error-msg-text">
              {selectionFocus && !validSelection
                ? "Required field is missing."
                : ""}
            </div>
            <textarea
              required
              onFocus={() => {
                setCommentFocus(true);
              }}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Comments"
              className="comments-textarea"
              style={{
                resize: "none",
                height: "140px",
                width: "298px",
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
                padding: "10px 15px",
                border: "1px solid",
                borderColor:
                  commentFocus && !validComments
                    ? "#c80e1a"
                    : validComments
                      ? "#01435b"
                      : "#acacac",
                font: "normal normal normal 16px/20px Helvetica",
                color: "#acacac",
              }}
            ></textarea>
            <div className="error-msg-text">
              {commentFocus && !validComments ? "Required field is missing." : ""}
            </div>
          </div>
          <div className="help-form-bottom">
            <button
              className="submit"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
            <div
              className="error-msg-text"
              style={{ textAlign: "center", marginBottom: "0" }}
            >
              {errMsg ? `${errMsg}` : ""}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HelpForm;
