import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Axios from "axios";
import "../../CSS/Header.css";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const UpdateImageTable = ({ setWarning, table }) => {
  const [response, setResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseText, setResponseText] = useState("");
  function updateCloudinaryImages() {
    Axios.post(`${api_address}/assets/updateCloudinaryImages`)
      .then((response) => {
        setLoading(false);
        setResponse(true);
        setResponseText(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setResponse(true);
        setResponseText("Network Error");

        console.error("Error:", error);
      });
  }

  return (
    <div className="popup">
      <div className="overlay"></div>

      <div
        className="popup-element"
        style={{
          width: "50rem",
          left: "28%",
          borderColor: "white",
          boxShadow: "1px 5px 5px grey",
          position: "fixed",
          height: "265px",
          marginTop: "4rem",
          padding: "0px 30px",
        }}
      >
        <AiOutlineCloseCircle
          className="close-button"
          onClick={() => {
            setWarning(false);
          }}
          style={{ top: "1rem", right: "1rem" }}
        />
        <h2
          style={{
            color: "red",
            textDecorationColor: "black",
            marginTop: "30px",
          }}
        >
          WARNING
        </h2>
        {!response && !loading ? (
          <>
            <p>
              By clicking update, you will be erasing the entire {table} image
              table and updating it with new data from cloudinary. Are you sure
              you want to continue?
            </p>
            <div style={{ padding: "0px 17rem" }}>
              <button
                className="permission-nav-item"
                style={{ marginTop: "2rem", cursor: "pointer" }}
                onClick={() => {
                  updateCloudinaryImages();

                  setLoading(true);
                }}
              >
                Continue
              </button>
              <button
                className="permission-nav-item"
                style={{ marginTop: "2rem", cursor: "pointer" }}
                onClick={() => {
                  setWarning(false);
                }}
              >
                Cancel
              </button>
            </div>
          </>
        ) : loading ? (
          <p>Updating...</p>
        ) : (
          <>
            {" "}
            <p>{responseText}</p>
            <div style={{ padding: "0px 21.5rem" }}>
              <button
                className="permission-nav-item"
                style={{ marginTop: "2rem", cursor: "pointer" }}
                onClick={() => {
                  setWarning(false);
                }}
              >
                Close
              </button>{" "}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UpdateImageTable;
