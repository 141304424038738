import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditTemplate from "../Components/EditorTools/EditTemplate";
import Axios from "axios";
import axios from "axios";
import RequireAuth from "../Components/Login/RequireAuth";
import { useSelector } from "react-redux";

const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const EditTable = () => {
  const auth = useSelector((state) => state.auth.value);
  const { table } = useParams();
  const [editTemplateParams, setEditTemplateParams] = useState({});

  async function getTableInfo() {
    try {
      const response = await axios.post(`${api_address}/edit/getTableInfo`, {
        table,
      });
      //   const data = response.data.reduce((acc, param) => {
      //     const [key, value] = Object.entries(param);
      //     acc[key] = value;
      //     return acc;
      //   }, {});
      //   console.log(data, 23);
      setEditTemplateParams(response.data[0]);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  useEffect(() => {
    getTableInfo();
  }, [table]);

  return (table === "users" || table === "nondealer_requests") &&
    (!auth?.rep || !auth?.roles?.includes(5150)) ? (
    <RequireAuth allowedRoles={[5150]} />
  ) : (
    Object.keys(editTemplateParams).length > 0 && (
      <EditTemplate {...editTemplateParams} />
    )
  );
};

export default EditTable;
